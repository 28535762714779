/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-prototype-builtins */

import React, {
  useState,
  useEffect,
  ReactElement,
  FC,
  Key,
  useRef,
} from "react";

import { Typography, Button, ModalConfirmation, StepIndicator } from "design_system/src";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Grid } from "@mui/material";
import useValidateStep from "../Hooks/useValidateStep";


interface IStepper {
  component: ReactElement<any, any>;
  validationKey?: string;
  stepTitle?: string;
  functionStep?: any;
  stepModalConfig?: any;
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 1,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 1,
    backgroundColor: theme.palette.mode === "light" ? "#FE8A02" : "#FE8A02",
  },
}));

type SimpleStepperProps = {
  hasProgressBar?: boolean;
  stepperComponents: Array<IStepper>[];
  validateStep: (step: any, values: any) => any;
  onSubmitForm?: any;
  loading?: boolean;
  activeStep: any;
  setActiveStep: any;
};

const SimpleStepper: FC<SimpleStepperProps> = ({
  hasProgressBar = true,
  stepperComponents,
  validateStep,
  onSubmitForm,
  loading,
  activeStep,
  setActiveStep,
}) => {
  const useStyles = makeStyles(() => ({
    header: {
      backgroundColor: "rgb(255,255,255)",
      borderRadiusTop: 50,
    },
    footer: {
      backgroundColor: "rgb(255,255,255)",
      padding: "1rem",
    },
  }));
  const classes = useStyles();
  const navigate = useNavigate();
  const intialRemainingState = stepperComponents
    .map((_: any, idx: number) => idx + 1)
    .slice(1);

  const [remainingSteps, setRemainingSteps] = useState<number[] | []>(
    intialRemainingState
  );
  const [currentSteps, setCurrentSteps] = useState<number[] | []>([1]);
  const [stepperBody, setStepperBody] = useState<any>();
  const [subcomponentCurrentStep, setSubcomponentCurrentStep] =
    useState<number>(0);
  const [stepModalProps, setStepModalProps] = useState<any>(undefined);

  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const handleClose = () => setOpenConfirmation(!openConfirmation);

  const subComponentsStepList = stepperComponents[activeStep];

  useEffect(() => {
    setCurrentSteps(
      stepperComponents
        .map((_: any, idx: number) => idx + 1)
        .filter((idx) => idx <= activeStep + 1)
    );
    setRemainingSteps(
      stepperComponents
        .map((_: any, idx: number) => idx + 1)
        .filter((idx) => idx > activeStep + 1)
    );
  }, [activeStep]);

  const incrementStep = () => {
    setActiveStep((prevActiveStep: any) => prevActiveStep + 1);
  };

  const decrementStep = () => {
    setActiveStep((prevActiveStep: any) => prevActiveStep - 1);
    setSubcomponentCurrentStep(0);
  };

  const maxSteps = stepperComponents.length;
  const isValid = () => {
    if (!stepperComponents[activeStep][subcomponentCurrentStep]) {
      setSubcomponentCurrentStep(0);
    }
    return useValidateStep(
      stepperComponents[activeStep][subcomponentCurrentStep]
        ? stepperComponents[activeStep][subcomponentCurrentStep].validationKey
        : stepperComponents[activeStep][0].validationKey,
      validateStep
    );
  };

  useEffect(() => {
    setStepperBody(subComponentsStepList[subcomponentCurrentStep].component);
  }, [activeStep, subcomponentCurrentStep]);

  const handleNext = async () => {
    let shouldSubmitForm = true;

    if (subcomponentCurrentStep === subComponentsStepList.length - 1) {
      if (activeStep === maxSteps - 1) {
        if (stepperComponents[activeStep][subcomponentCurrentStep].functionStep) {
          shouldSubmitForm = await stepperComponents[activeStep][subcomponentCurrentStep].functionStep();
        }
        if (shouldSubmitForm !== false && onSubmitForm) {
          onSubmitForm();
        }
      } else {
        if (stepperComponents[activeStep][subcomponentCurrentStep].functionStep) {
          shouldSubmitForm = await stepperComponents[activeStep][subcomponentCurrentStep].functionStep();
        }
        if (shouldSubmitForm !== false) {
          incrementStep();
          setSubcomponentCurrentStep(0);
        }
      }
    } else {
      if (stepperComponents[activeStep][subcomponentCurrentStep].functionStep) {
        shouldSubmitForm = await stepperComponents[activeStep][subcomponentCurrentStep].functionStep();
      }
      if (shouldSubmitForm !== false) {
        setSubcomponentCurrentStep((prevState) => prevState + 1);
      }
    }
  };

  const handleBack = () => {
    if (subcomponentCurrentStep === 0 && activeStep === 0) {
      navigate(-1);
      return;
    }
    if (subcomponentCurrentStep === 0) {
      decrementStep();
      return;
    }
    setSubcomponentCurrentStep((prevState) => prevState - 1);
  };

  const renderNextTextButton = (): string => {
    if (loading) {
      return "Enviando...";
    }
    if (
      activeStep === maxSteps - 1 &&
      subcomponentCurrentStep === subComponentsStepList.length - 1
    ) {
      return "Continuar";
    }
    return "Siguiente";
  };

  const onConfirmationCallBack = async () => {
    handleClose();
    handleNext();
  };

  const handleOnclickBehaivor = () => {
    if (stepModalProps) {
      const openModal = stepModalProps.handleOpen ? stepModalProps.handleOpen() : true;
      setOpenConfirmation(openModal);
      if (openModal) {
        return;
      }
    }

    if (
      activeStep === maxSteps - 1 &&
      subcomponentCurrentStep === subComponentsStepList.length - 1
    ) {
      onSubmitForm();
      return;
    }
    handleNext();
  };

  const setProgressBarValue = (idx: Key) =>
    hasProgressBar ? (idx <= activeStep ? 100 : 0) : 0;

  const componentStartRef = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    componentStartRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }, [activeStep, subcomponentCurrentStep]);

  useEffect(() => {
    if (
      stepperComponents[activeStep][subcomponentCurrentStep].stepModalConfig
    ) {
      setStepModalProps(
        stepperComponents[activeStep][subcomponentCurrentStep].stepModalConfig
      );
    } else {
      setStepModalProps(undefined);
    }
  }, [stepperComponents, activeStep, subcomponentCurrentStep]);

  return (
    <div>
      <div>
        <div>
          <Box ref={componentStartRef} className={`${classes.header} p_y_sm_mobile p_t_md_desktop p_r_sm_mobile p_r_md_desktop`}>
            <Grid container direction="row" spacing={0}>
              <Grid item xs={12} md={8} className="m_b_md p_l_xl">
                <Typography
                  className="text_primary_300"
                  scale="medium"
                  weight="600"
                >
                  <span>
                    {currentSteps.map((step: any, idx: number) => (
                      <StepIndicator
                        key={idx + 1}
                        currentNumber={step}
                        isActive
                        isClickeable
                        steps={stepperComponents}
                        setStepperBody={setStepperBody}
                        setActiveStep={setActiveStep}
                        setCurrentSteps={setCurrentSteps}
                        setRemainingSteps={setRemainingSteps}
                      />
                    ))}
                  </span>{" "}
                  {stepperComponents[activeStep][0].stepTitle}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <span>
                  {remainingSteps.map((step: any, idx: number) => (
                    <StepIndicator key={idx + 1} currentNumber={step}/>
                  ))}
                </span>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            {hasProgressBar && (
              <Box display="flex" justifyContent="center" alignItems="center">
                <Grid spacing={0} container padding={0}>
                  {stepperComponents.map((_: any, idx: Key) => (
                    <Grid key={idx} xs item>
                      <BorderLinearProgress
                        variant="determinate"
                        value={setProgressBarValue(idx!)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
            <Box
              sx={{
                width: "100%",
                p: "5px 5%",
                marginTop: "10px",
                minHeight: 350,
              }}
            >
              {stepperBody}
            </Box>
            <div className={classes.footer}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                sx={{
                  "@media (max-width: 899px)": {
                    flexDirection: "column-reverse",
                  },
                }}
                spacing={2}
              >
                {
                  (activeStep > 0 || (activeStep === 0 && subcomponentCurrentStep > 0)) && <Grid item xs={12} md={6}>
                    <Button
                      className="w_100_per"
                      variant="outline"
                      scale="small"
                      icon={<ArrowBackIcon />}
                      onClick={handleBack}
                    >
                      Volver
                    </Button>
                  </Grid>
                }

                <Grid item xs={12} md={(activeStep > 0 || (activeStep === 0 && subcomponentCurrentStep > 0)) ? 6 : 12}>
                  {loading ? (
                    <Box
                      className="m_t_xs"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        weight="400"
                        scale="medium"
                        textColor="neutral_600"
                        className="m_x_lg"
                      >
                        Enviando...
                      </Typography>
                      <CircularProgress size={22} sx={{ color: "#FE8A02" }} />
                    </Box>
                  ) : (
                    <Button
                      className="w_100_per"
                      orientation="right"
                      scale="small"
                      icon={<ArrowForwardIcon />}
                      disabled={!isValid() || loading}
                      onClick={handleOnclickBehaivor}
                    >
                      {renderNextTextButton()}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </div>
          </Box>
        </div>
      </div>
      {stepModalProps && (
        <ModalConfirmation
          widthDesktop={stepModalProps.widthDesktop}
          heightDesktop={stepModalProps.heightDesktop}
          icon={stepModalProps.icon}
          handleClose={handleClose}
          openConfirmation={openConfirmation}
          onConfirmationCallBack={() => onConfirmationCallBack()}
          isConfirmationLoading={false}
          title={stepModalProps.title}
          subtitle={stepModalProps.subtitle}
          copyReject="No, regrésame"
          copyConfirm="Si, seguro"
        />
      )}
    </div>
  );
};

export default SimpleStepper;
