/* eslint-disable react/no-unstable-nested-components */
import React, { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Outlet, Route, RouteProps, useLocation } from "react-router-dom";
import { store } from "store";
import { logout } from "store/actions/user";
import { fetchOzonerGeolocation } from "helpers/fetchGeolocation";
import { getOzonerLocation } from "helpers/getOzonerLocation";
import { fetchGetFinancialForm } from "helpers/fetchMiCuenta";
import { transformFinancialFormObj, useFinancialForm } from "hooks/useGetFinancialForm";

interface PrivateRouteProps  {
    component: React.ComponentType<any>;
}

export const MiCuentaRoute= () => {
    const { jwt, refreshToken, user } = store.getState().userReducer;
    const dispatch = useDispatch();
    const [financialForm, setFinancialForm] = useFinancialForm();
    const location = useLocation();

    useLayoutEffect(() => {
        if (!(jwt !== "" && refreshToken !== "" && user.account?.isActive === true)) {
            dispatch(logout());
        } else if (location.pathname === "/mi-cuenta") {
            getOzonerLocation().then((coords) => {
                fetchOzonerGeolocation(coords);
            });
        }

        if (financialForm) {
            fetchGetFinancialForm(financialForm._id)
                .then((data) => {
                    setFinancialForm(transformFinancialFormObj(data.ozoner));
                });
        } else {
            store.dispatch(logout());
        }
    }, []);

    return jwt !== "" && refreshToken !== "" && user.account?.isActive === true ? (
            <Outlet/>
        ) : (
            <Navigate replace to="/ingresar"/>
        );
};
