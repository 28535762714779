import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import axios from "axios";

import { Layout } from "components/layouts/Layout";
import { changeCountry } from "store/actions/country";

import { MiCuentaRoute } from "views/miCuenta/router/MiCuentaRoute";

import { NotFoundScreen } from "views/NotFoundScreen";
import { HomeScreen } from "views/HomeScreen";
import { TermsScreen } from "views/TermsScreen";
import { PrivacyScreen } from "views/PrivacyScreen";
import { OzocioHomeScreen } from "views/ozocioHome/OzocioHomeScreen";
import { OzocioFormScreen } from "views/ozocioForm/OzocioFormScreen";
import { OzonFlyScreen } from "views/ozonFly/OzonFlyScreen";
import { RejectedView } from "views/ozonFly/rejectedView/RejectedView";
import { ContinueView } from "views/ozonFly/continueView/ContinueView";
import { SummaryView } from "views/ozonFly/summaryView/SummaryView";

import CreditCalculation from "views/creditCalculation/CreditCalculation";

import { LoginScreen } from "views/auth/login/LoginScreen";
import { LogoutScreen } from "views/auth/logout/LogoutScreen";
import { ActivateMiCuentaScreen } from "views/auth/activateAcccount/ActivateMiCuenta";
import { RecoverPasswordScreen } from "views/auth/recoverPassword/RecoverPasswordScreen";
import { MiCuentaScreen } from "views/miCuenta/MiCuentaScreen";
import { ApplicationScreen } from "views/miCuenta/application/ApplicationScreen";
import { ApplicationDocumentsScreen } from "views/miCuenta/applicationDocuments/ApplicationDocumentsScreen";
import { FaqsScreen } from "views/faqs/FaqsScreen";

import NewCatalog from "../components/catalog/NewCatalog";
import NewDetailsView from "../views/DetailsView/NewDetailsView";
import NoAvailableVehicle from "../views/noAvailableVehicle/NoAvailableVehicle";

import ScrollToTop from "../components/scrollToTop/ScrollToTop";

export const RootRouter = () => {
  const dispatch = useDispatch();
  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        const { data } = response;

        let isoCountry = "";
        if (data.country === "MX" || data.country === "CO") {
          isoCountry = data.country === "MX" ? "MX" : "CO";
        } else {
          isoCountry = "MX";
        }

        localStorage.setItem("country-ozon", `${isoCountry}`);
        dispatch(changeCountry(isoCountry));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getGeoInfo();
  }, [dispatch]);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Layout>
        <Routes>
          {/* Landing pages */}
          <Route path="/" element={<HomeScreen />} />
          <Route path="/faqs" element={<FaqsScreen />} />
          <Route path="/terminos-y-condiciones" element={<TermsScreen />} />
          <Route path="/aviso-de-privacidad" element={<PrivacyScreen />} />

          {/* Ozonio */}
          <Route path="/vende-tu-moto" element={<OzocioHomeScreen />} />
          <Route
            path="/formulario-vende-tu-moto"
            element={<OzocioFormScreen />}
          />

          {/* Catalog */}
          <Route path="/catalogo" element={<NewCatalog />} />
          <Route path="/catalogo/:id" element={<NewDetailsView />} />
          <Route
            path="/vehiculo-no-disponible"
            element={<NoAvailableVehicle />}
          />

          {/* Ozoner */}
          <Route path="/financia-tu-moto" element={<CreditCalculation />} />
          <Route
            path="/financia-tu-moto/documentos/:formId"
            element={<OzonFlyScreen />}
          />
          <Route path="/rechazo-solicitud" element={<RejectedView />} />
          <Route path="/documentos-en-proceso" element={<ContinueView />} />
          <Route path="/solicitud-en-proceso" element={<SummaryView />} />

          {/* Auth */}
          <Route path="/ingresar" element={<LoginScreen />} />
          <Route
            path="/recuperar-contrasena"
            element={<RecoverPasswordScreen />}
          />
          <Route path="/logout" element={<LogoutScreen />} />
          <Route path="/activar-cuenta" element={<ActivateMiCuentaScreen />} />

          {/* Mi Cuenta */}
          <Route element={<MiCuentaRoute />}>
            <Route path="/mi-cuenta" element={<MiCuentaScreen/>} />
            <Route
              path="/mi-cuenta/solicitud"
              element={<ApplicationScreen/>}
            />
            <Route
              path="/mi-cuenta/documentos"
              element={<ApplicationDocumentsScreen/>}
            />
          </Route>

          {/* Legacy */}
          <Route path="/offererdate" element={<Navigate replace to="/" />} />
          <Route
            path="/privacy"
            element={<Navigate replace to="/aviso-de-privacidad" />}
          />
          <Route path="/micuenta" element={<Navigate replace to="/" />} />
          <Route path="/micuenta/:id" element={<Navigate replace to="/" />} />
          <Route
            path="/recover-password"
            element={<Navigate replace to="/" />}
          />
          <Route path="/palenca" element={<Navigate replace to="/" />} />
          <Route
            path="/validateIdentity/:formId"
            element={
              <Navigate replace to="/financia-tu-moto/documentos/:formId" />
            }
          />
          <Route
            path="/validateIdentity"
            element={<Navigate replace to="/" />}
          />
          <Route
            path="/continueForm"
            element={<Navigate replace to="/documentos-en-proceso" />}
          />
          <Route
            path="/summaryForm"
            element={<Navigate replace to="/solicitud-en-proceso" />}
          />
          <Route
            path="/financia-tu-moto/results/:selectedVehicleId?"
            element={<Navigate replace to="/" />}
          />

          <Route element={<NotFoundScreen />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};
