/* eslint-disable no-unused-vars */

import React, { useState } from "react";

import { Grid, useMediaQuery } from "@mui/material";
import { Typography } from "design_system/src";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  clearCreditVerificationCreditTimeAction,
  clearCreditVerificationVehicleAction,
} from "store/actions/creditVerification";
import { FilterTagList } from "components/catalog/filterTagList/FilterTagList";
import { TVehicle } from "../../models/vehicle.interface";
import OzonCardVehicle from "../cards/OzonCardVehicle";

import NoResultImage from "../../static/images/noResult.png";

type OzonPaginationProps = {
  vehicles: TVehicle[];
  helperCardPos: number;
  updateCatalogFilters: any;
};

const NoResult = () => {
  return (
    <div
      style={{
        height: "70vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginRight: "20%",
        padding: "10%",
        textAlign: "center",
      }}
    >
      <img height="100" src={NoResultImage} alt="noResult" />
      <Typography weight="400" scale="medium">
        No hay resultados con los citerios de búsqueda
      </Typography>
    </div>
  );
};

const OzonCardVehiclesPagination: React.FC<OzonPaginationProps> = ({
  vehicles,
  helperCardPos,
  updateCatalogFilters,
}) => {
  const matchesXS = useMediaQuery("(min-width:600px)");
  const matchesMD = useMediaQuery("(min-width:900px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const triggerCard = () => {
    navigate("/financia-tu-moto");
    dispatch(clearCreditVerificationCreditTimeAction());
    dispatch(clearCreditVerificationVehicleAction());
  };

  const resultado: any[] = [];
  vehicles
    .filter(vehicle => vehicle.salePrices && vehicle.salePrices.length > 0)
    .forEach((vehicle, index) => {
      resultado.push(vehicle);
      if ((index + 1) % (helperCardPos - 1) === 0) {
          resultado.push(null);
      }
  });

  return vehicles.length === 0 ? (
    <NoResult />
  ) : (
    <>
      <div className={`${!matchesMD ? "" : "display_none"} m_y_sm`}>
        <FilterTagList updateCatalogFilters={updateCatalogFilters}/>
        <Typography
          scale="medium"
          className="display_flex p_y_md p_l_sm"
          weight="400"
        >
          <b className="m_r_xs">{vehicles.length} </b> moto
          {vehicles.length !== 1 ? "s" : ""}
        </Typography>
      </div>

      <Grid
        container
        rowSpacing={!matchesXS ? 2 : 3}
        columnSpacing={!matchesXS ? 0 : 2}
      >
        {resultado
          .map((vehicle, idx) => {
            return (
              <Grid
                key={`VehicleCardContainer-${idx}`}
                item
                xs={12}
                sm={4}
                md={4}
                lg={3}
                xl={3}
                style={{ minHeight: "100%" }}
              >
                <OzonCardVehicle
                  isHelperCard={vehicle === null}
                  vehicle={vehicle}
                  triggerCard={triggerCard}
                />
              </Grid>
            );
          })}
      </Grid>
    </>
  );
};

export default OzonCardVehiclesPagination;
