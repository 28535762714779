import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { FormikHelpers, FormikProvider, useFormik, useFormikContext } from "formik";

import Grid from "@mui/material/Grid";
import { Typography, Button } from "design_system/src";
import { ReactComponent as Invoice } from "design_system/src//static/icons/invoice.svg";
import { ReactComponent as Clock } from "design_system/src//static/icons/clock.svg";
import { ReactComponent as UserCircle } from "design_system/src//static/icons/user-circle.svg";
import { ReactComponent as GPS } from "design_system/src//static/icons/gps.svg";
import { ReactComponent as Dollar } from "design_system/src//static/icons/dollar.svg";

import { FinancialFormStatus } from "models/financialForm.interface";

import { WhatsappButton } from "views/miCuenta/components/whatsappButton/WhatsappButton";
import { ValidationIdentityFormEnum } from "enums/validationIdentityForm.enum";
import { IDocument } from "helpers/validateIdentity";
import { useUploadFiles } from "views/ozonFly/hooks/useUploadFiles";
import UploadInput, { getBase64 } from "views/ozonFly/uploadInput/UploadInput";
import { useFinancialForm, transformFinancialFormObj } from "hooks/useGetFinancialForm";
import { store } from "store";

import { ReactComponent as ViewerIcon } from "static/images/uploadInput/documento_preview_icono.svg";
import UploadPdfBk from "static/images/uploadInput/documento_preview_recibo.png";
import { format } from "date-fns";
import moment from "moment";
import { FinancialFormDocs } from "enums/financialFormDocs.enum";
import { fetchGetSellResponsible } from "helpers/fetchMiCuenta";

const fetchImageAsBlob = async (
    url: string,
    name: string
): Promise<{
    imageData: string;
    imageFile: File;
    name: string;
}> => {
    try {
        const response = await fetch(url);
        const blob: Blob = await response.blob();
        const file = new File([blob], `${name}.jpg`, {
            type: blob.type,
            lastModified: new Date().getTime(),
        });
        const base64 = await getBase64(file);
        return { imageData: base64, imageFile: file, name };
        // eslint-disable-next-line no-shadow
    } catch (error) {
        throw new Error("Image response was not ok");
    }
};

export const DocumentsStep = () => {
    const navigate = useNavigate();
    const [financialForm, setFinancialForm] = useFinancialForm();
    const IMG_FORMATS = [".jpg", ".png", ".jpeg"];
    const DOCUMENT_NAMES = {
        [ValidationIdentityFormEnum.SELFIE.split(".")[1]]: "Selfie",
        [ValidationIdentityFormEnum.INE_FRONT.split(".")[1]]: "INE frontal",
        [ValidationIdentityFormEnum.INE_BACK.split(".")[1]]: "INE Posterior",
        [ValidationIdentityFormEnum.PROOF_OF_ADDRESS.split(".")[1]]: "Domicilio",
        [ValidationIdentityFormEnum.BANK_CERTIFICATE_1.split(".")[1]]: "Certificado de ingresos 1",
        [ValidationIdentityFormEnum.BANK_CERTIFICATE_2.split(".")[1]]: "Certificado de ingresos 2",
        [ValidationIdentityFormEnum.BANK_CERTIFICATE_3.split(".")[1]]: "Certificado de ingresos 3",
    };

    useEffect(() => {
        ReactGA.event("VIEW_MyAccount_documents_summary", {
            category: "VIEW_MyAccount_documents_summary",
            label:"page view tab with document summary",
        });
    }, []);

    const renderCard = (key: string) => {
        const doc = financialForm?.documents.filter((e) => e.name === key)[0];
        if (doc) {
            return (
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={3}
                    lg={3}
                    className="p_md"
                >
                    <Typography scale="small" weight="400" className="text_center m_b_sm">{DOCUMENT_NAMES[key]}</Typography>
                    <a className="w_100_per pos_relative" href={doc.url} target="_blank" rel="noreferrer">
                        <div
                            className="dso_card br_md image_preview fill m_b_lg"
                            style={{
                                backgroundImage: !IMG_FORMATS.includes(doc.ext)
                                    ? `url(${UploadPdfBk})`
                                    : "",
                                backgroundSize: "cover",
                                }}
                        >
                            {!IMG_FORMATS.includes(doc.ext) ? (
                                <div className="dso_card_img border_primary_300 bg_neutral_200 p_lg display_flex w_fit center">
                                    <ViewerIcon className="dim_xl" />
                                </div>
                            ) : (
                                <img className="" src={doc.url} alt="" />
                            )}
                        </div>
                    </a>
                </Grid>
            );
        }
        return(<div/>);
    };

    return <div className="w_100_per m_x_auto" style={{ maxWidth: 700 }}>
        <Typography className="text_center" weight="600" scale="medium" component="h2">Registro de documentos</Typography>
        <Grid container spacing={0}>
            {Object.keys(DOCUMENT_NAMES).map((key) => {return renderCard(key);})}
        </Grid>
        {financialForm?.status === FinancialFormStatus.PENDING && <Button className="w_100_per p_y_none" scale="small" variant="ghost" onClick={() => navigate("/mi-cuenta/documentos")}>
            <Typography weight="600" scale="medium" scaleMobile="medium" textColor="neutral_700">
                Editar documentos
            </Typography>
        </Button>}
    </div>;
};

export const CreditValidationStep = () => {
    const [financialForm, setFinancialForm] = useFinancialForm();
    const [status, setStatus] = useState<FinancialFormStatus>(FinancialFormStatus.IN_VALIDATION);
    const [username, setUsername] = useState<string>("");

    useEffect(() => {
        ReactGA.event("VIEW_MyAccount_in_validation", {
            category: "VIEW_MyAccount_in_validation",
            label:"page view tab with in validation instructions",
        });
    }, []);

    useEffect(() => {
        if (financialForm) {
            setStatus(financialForm.status);
            setUsername(financialForm.userInfoStep.name);
        }
    }, [financialForm]);

    return <div className="w_100_per m_x_auto" style={{ maxWidth: 700 }}>
        <Typography className="text_center" weight="600" scale="medium" component="h2">Validación de crédito</Typography>
        <div className="p_y_xxxl w_50_per_desktop m_x_auto">
            {status === "pending" || status === "inValidation" && <div>
                <Typography
                    className="text_center"
                    weight="400"
                    scale="medium"
                    component="p"
                >
                    Tu crédito esta <strong>siendo validado</strong> por nuestra área de crédito
                </Typography>
            </div>}
            {status === "rejected" && <div>
                <Typography
                    className="text_center"
                    weight="400"
                    scale="medium"
                    component="p"
                >
                    Tu crédito ha sido <strong className="text_red_300">rechazado</strong>:
                </Typography>
                <Typography
                    className="text_center"
                    weight="400"
                    scale="medium"
                    component="p"
                >
                    Hola {username} por ahora no podemos seguir con la financiación de tu moto pero te invitamos a intentarlo en un futuro.
                </Typography>
            </div>}
            {(status !== "pending" && status !== "rejected" &&  status !== "inValidation") && <div>
                <Typography
                    className="text_center"
                    weight="400"
                    scale="medium"
                    component="p"
                >
                    Tu crédito ha sido <strong className="text_green_300">aprobado</strong> por una cuota semanal máxima de:
                </Typography>
                <Typography
                    className="text_center"
                    weight="600"
                    scale="heading4"
                    component="p"
                >
                    ${financialForm?.maxWeekQuota}
                </Typography>
            </div>}
        </div>
    </div>;
};

export const MotorcycleStep = () => {
    const [financialForm, setFinancialForm] = useFinancialForm();
    const [vehicle, setVehicle] = useState<any>();
    const [vehicleAvailable, setVehicleAvailable] = useState<boolean>();
    const defaultMoto = "https://ozon-prod-storage-bucket.s3.amazonaws.com/public/uploads/generic-moto.png";

    useEffect(() => {
        ReactGA.event("VIEW_MyAccount_vehicle", {
            category: "VIEW_MyAccount_vehicle",
            label:"page view tab with vehicle instructions",
        });
    }, []);

    useEffect(() => {
        setVehicle(financialForm?.vehicle);
        setVehicleAvailable(financialForm?.vehicleAvailable === true);
    }, [financialForm]);

    const creditNames: any = {
        "52": "Facilidad",
        "78": "Oportunidad",
        "104": "Comodidad",
        "156": "Seguridad",
    };

    return <div className="w_100_per m_x_auto">
        <Typography className="text_center" weight="600" scale="medium" component="h2">Selección de moto</Typography>
        {vehicle && <div className="p_y_xxxl w_70_per_desktop m_x_auto">
            <div>
                {!vehicleAvailable && <Typography
                    className="text_center text_red_300"
                    weight="600"
                    scale="medium"
                    component="p"
                >
                    Vehículo no disponible
                </Typography>}
                <div
                    className="display_flex flex_row_desktop flex_col_mobile"
                    style={{
                        opacity: vehicleAvailable ? 1 : 0.4
                    }}
                >
                    <div className="w_100_per_mobile display_flex flex_center">
                        <img
                            className="w_200_px h_150_px br_sm"
                            style={{ objectFit: "cover" }}
                            src={vehicle.images.length > 0 ? vehicle.images[0].url : defaultMoto}
                            alt={vehicle.images.length > 0 ? vehicle.images[0].name : "ozon-mototcycle"}
                        />
                    </div>
                    <div className="flex_grow_1 p_x_xl_desktop p_y_sm_mobile display_flex flex_wrap flex_justify_between">
                        <div>
                            <div className="m_b_md">
                                <Typography weight="600" scale="medium">{vehicle.internalId}</Typography>
                                <Typography weight="600" scale="medium">{vehicle.brand.name} {vehicle.model.name}</Typography>
                                <Typography weight="400" scale="small">{vehicle.details.year} | {vehicle.cylindersCapacity}cc | {vehicle.details.milage}km</Typography>
                            </div>
                            {financialForm?.creditTime && <div className="m_b_md">
                                <Typography weight="600" scale="medium">Valor cuota semanal</Typography>
                                <Typography weight="400" scale="medium">${vehicle.salePrices.filter((e: any) => e.weeks === financialForm?.creditTime)[0].paymentWeek}</Typography>
                            </div>}
                        </div>
                        {financialForm?.creditTime && <div className="m_b_md">
                            <Typography weight="600" scale="medium">Forma de pago</Typography>
                            <Typography weight="600" scale="medium" className="text_primary_300">{creditNames[financialForm?.creditTime]}</Typography>
                            <Typography weight="400" scale="medium">{financialForm?.creditTime} semanas</Typography>
                        </div>}
                    </div>
                </div>
                {!vehicleAvailable && <div>
                    <Typography
                        className="text_center"
                        weight="400"
                        scale="medium"
                        component="p"
                    >
                        Selecciona una moto diferente con <strong>ayuda de tu asesor</strong>
                    </Typography>
                    <WhatsappButton/>
                </div>}
            </div>
        </div>}
        {!vehicle && <div className="p_y_xxxl w_50_per_desktop m_x_auto">
            <div>
                <Typography
                    className="text_center"
                    weight="400"
                    scale="medium"
                    component="p"
                >
                    Selecciona una moto con <strong>ayuda de tu asesor</strong>
                </Typography>
                <WhatsappButton/>
            </div>
        </div>}
    </div>;
};

const InputComponent = () => {
    const {
        values,
        handleChange,
        errors,
        handleBlur,
        getFieldMeta,
        setFieldValue,
    } = useFormikContext<any>();
    const useChangeFile = useUploadFiles();

    return <UploadInput
        onChange={(props) => useChangeFile({...props, location: "MyAccount"})}
        value={_.get(values, ValidationIdentityFormEnum.RESERVATION_RECEIPT)}
        name={ValidationIdentityFormEnum.RESERVATION_RECEIPT}
        error={(_.get(errors, ValidationIdentityFormEnum.RESERVATION_RECEIPT) as string) ?? undefined}
        title="Sube o toma la foto del comprobante de apartado"
        placeholderIcon={<Invoice className="dim_xl" />}
        accept="image/jpeg,image/png"
    />;
};

export const ReservationStep = () => {
    type DocumentType = IDocument | null | undefined;

    interface ReservationReceiptFormValues {
        [ValidationIdentityFormEnum.RESERVATION_RECEIPT_STEP]: {
            reservation_receipt: DocumentType;
        };
    }

    const SUPPORTED_FORMATS = ["image/jpg", "image/png", "image/jpeg"];

    const validationSchema = yup.object().shape({
        [ValidationIdentityFormEnum.RESERVATION_RECEIPT_STEP]: yup.object().shape({
            ine_front: yup
                .mixed()
                .required("Campo requerido")
                .test("fileSize", "El archivo es demasiado grande", (data) => {
                    if (data?._id) {
                        return true;
                    }
                    if (!data || !data.imageFile) {
                        return false;
                    }
                return data.imageFile.size <= 1000000 * 5;
                })
                .test(
                    "format",
                    "El archivo no corresponde al formato jpg/jpeg o png",
                    (value) => {
                        if (value?._id) {
                            return true;
                        }
                        if (!value || !value.imageFile || !value.imageFile.type) {
                            return false;
                        }
                        return SUPPORTED_FORMATS.includes(value.imageFile.type);
                    }
                )
        }),
    });

    const [financialForm, setFinancialForm] = useFinancialForm();
    const { user } = store.getState().userReducer;

    const form = useFormik<ReservationReceiptFormValues>({
        initialValues: {
            [ValidationIdentityFormEnum.RESERVATION_RECEIPT_STEP]: {
                reservation_receipt: null,
            }
        },
        validationSchema,
        onSubmit: () => {},
    });

    useEffect(() => {
        ReactGA.event("VIEW_MyAccount_layaway_receipt_document", {
            category: "VIEW_MyAccount_layaway_receipt_document",
            label:"page view tab with layaway receipt document instructions",
        });
    }, []);

    useEffect(() => {
        if (financialForm) {
            const { documents } = financialForm;
            const layawayReceiptDoc = documents.filter((doc) => doc.typeEvidence === FinancialFormDocs.RESERVATION_RECEIPT);
            layawayReceiptDoc.forEach((e) => {
                if (e.typeEvidence === FinancialFormDocs.RESERVATION_RECEIPT) {
                    fetchImageAsBlob(e.url, e.name).then((imageFile) => {
                        form.setFieldValue(ValidationIdentityFormEnum.RESERVATION_RECEIPT, imageFile, true);
                    });
                }
            });
            if (layawayReceiptDoc.length === 0) {
                form.setFieldValue(ValidationIdentityFormEnum.RESERVATION_RECEIPT, null, true);
            }
        }
    }, [financialForm, ]);

    useEffect(() => {
        setFinancialForm(transformFinancialFormObj(user));
    }, [user]);

    return <div className="w_100_per m_x_auto" style={{ maxWidth: 700 }}>
        <FormikProvider value={form}>
            <Typography className="text_center" weight="600" scale="medium" component="h2">Comprobante de apartado</Typography>
            <div className="animate__animated animate__fadeIn p_x_lg p_y_xl">
                <Grid container justifyContent="center">
                    <Grid item md={6} xs={12} className="p_x_xxxl_desktop p_x_md_mobile">
                        <InputComponent/>
                    </Grid>
                </Grid>
            </div>
        </FormikProvider>
    </div>;
};

export const SchedulingStep = () => {
    const { user } = store.getState().userReducer;
    const [financialForm, setFinancialForm] = useFinancialForm();
    const [appointment, setAppointment] = useState<string | null>(null);
    const [sellerName, setSellerName] = useState<string | null>(null);
    const [vehicle, setVehicle] = useState<any>();
    const defaultMoto = "https://ozon-prod-storage-bucket.s3.amazonaws.com/public/uploads/generic-moto.png";

    useEffect(() => {
        ReactGA.event("VIEW_MyAccount_date_scheduled", {
            category: "VIEW_MyAccount_date_scheduled",
            label:"page view tab with date scheduled instructions",
        });
    }, []);

    useEffect(() => {
        if (financialForm) {
            setVehicle(financialForm?.vehicle);
            setAppointment(financialForm?.appointment?.scheduledAt ?? null);
            fetchGetSellResponsible(user._id, financialForm._id)
                .then((data) => setSellerName(data.name));
        }
    }, []);

    return <div className="w_100_per m_x_auto">
        <Typography className="text_center" weight="600" scale="medium" component="h2">Agendar entrega</Typography>
        <div className="p_y_xxxl w_80_per_desktop m_x_auto">
            {appointment
                ? (<div>
                    <div
                        className="display_flex flex_row_desktop flex_col_mobile"
                    >
                        <div className="w_100_per_mobile display_flex flex_center flex_col">
                            <img
                                className="w_200_px h_150_px br_sm"
                                style={{ objectFit: "cover" }}
                                src={vehicle.images.length > 0 ? vehicle.images[0].url : defaultMoto}
                                alt={vehicle.images.length > 0 ? vehicle.images[0].name : "ozon-mototcycle"}
                            />
                            <Typography weight="600" scale="medium">{vehicle.brand.name} {vehicle.model.name}</Typography>
                        </div>
                        <div className="flex_grow_1 p_x_xl_desktop p_y_sm_mobile display_flex flex_wrap flex_justify_between">
                            <div>
                                <div className="m_b_md display_flex flex_align_center">
                                    <Clock className="m_r_sm"/>
                                    <div>
                                        <Typography
                                            weight="600"
                                            scale="medium"
                                        >
                                            Fecha y hora de entrega
                                        </Typography>
                                        <Typography
                                            weight="400"
                                            scale="medium"
                                        >
                                            {moment(appointment).utcOffset(-360).format("YYYY-MM-DD hh:mm A")}
                                        </Typography>
                                    </div>
                                </div>
                                <div className="m_b_md display_flex flex_align_center">
                                    <GPS className="m_r_sm"/>
                                    <div>
                                        <Typography
                                            weight="600"
                                            scale="medium"
                                        >
                                            Lugar
                                        </Typography>
                                        <Typography
                                            weight="400"
                                            scale="medium"
                                        >
                                            Laguna de Mayran 396,<br/>Anáhuac I Secc.,<br/>Anáhuac I Secc,<br/>Miguel Hidalgo, 11320<br/>Ciudad de México,<br/>CDMX, México
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                            <div>
                                {financialForm?.creditTime && <div className="m_b_md display_flex flex_align_center">
                                    <Dollar className="m_r_sm"/>
                                    <div>
                                        <Typography
                                            weight="600"
                                            scale="medium"
                                        >
                                            Valor de la primera cuota
                                        </Typography>
                                        <Typography
                                            weight="400"
                                            scale="medium"
                                        >
                                            ${vehicle.salePrices.filter((e: any) => e.weeks === financialForm?.creditTime)[0].paymentWeek + (financialForm?.deposit ?? 0) }
                                        </Typography>
                                    </div>
                                </div>}
                                {sellerName && <div className="m_b_md display_flex flex_align_center">
                                    <UserCircle className="m_r_sm"/>
                                    <div>
                                        <Typography
                                            weight="600"
                                            scale="medium"
                                        >
                                            Nombre de asesor
                                        </Typography>
                                        <Typography
                                            weight="400"
                                            scale="medium"
                                        >
                                            {sellerName}
                                        </Typography>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>

                    <div className="bg_primary_25 p_y_md p_x_xl br_md m_x_auto" style={{ maxWidth: 400 }}>
                        <Typography
                            className="m_l_sm"
                            weight="400"
                            scale="medium"
                            component="p"
                        >
                            <strong>Importante</strong> para el día de la entrega
                        </Typography>
                        <div>
                            <div className="display_flex flex_align_start m_b_sm">
                                <div className="w_10_per display_flex">
                                    <Typography
                                        className="bg_primary_300 br_circle m_none w_20_px h_20_px display_flex flex_center"
                                        weight="600"
                                        scale="small"
                                        component="p"
                                    >
                                        1
                                    </Typography>
                                </div>
                                <Typography
                                    className="m_l_sm m_y_none w_80_per"
                                    weight="400"
                                    scale="small"
                                    component="p"
                                >
                                    Revisa y firma el contrato con tu asesor
                                </Typography>
                            </div>
                            <div className="display_flex flex_align_start m_b_sm">
                                <div className="w_10_per display_flex">
                                    <Typography
                                        className="bg_primary_300 br_circle m_none w_20_px h_20_px display_flex flex_center"
                                        weight="600"
                                        scale="small"
                                        component="p"
                                    >
                                        2
                                    </Typography>
                                </div>
                                <Typography
                                    className="m_l_sm m_y_none w_80_per"
                                    weight="400"
                                    scale="small"
                                    component="p"
                                >
                                    Realiza el depósito de garantía y tu primera semanalidad
                                </Typography>
                            </div>
                            <div className="display_flex flex_align_start m_b_sm">
                                <div className="w_10_per display_flex">
                                    <Typography
                                        className="bg_primary_300 br_circle m_none w_20_px h_20_px display_flex flex_center"
                                        weight="600"
                                        scale="small"
                                    >
                                        3
                                    </Typography>
                                </div>
                                <Typography
                                    className="m_l_sm m_y_none w_90_per"
                                    weight="400"
                                    scale="small"
                                    component="p"
                                >
                                    Revisa tu moto y llévatela a rodar
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>)
                : (<div>
                    <Typography
                        className="text_center"
                        weight="400"
                        scale="medium"
                        component="p"
                    >
                        Agenda el día de entrega con ayuda de tu asesor
                    </Typography>
                    <WhatsappButton/>
                </div>)
            }
        </div>
    </div>;
};
