import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { store } from "store";
import { logout } from "store/actions/user";

import { fetchLogout } from "helpers/fetchMiCuenta";
import { useFinancialForm } from "hooks/useGetFinancialForm";
import { useDispatch } from "react-redux";

export const LogoutScreen = () => {
    const navigate = useNavigate();
    const [financialForm, setFinancialForm] = useFinancialForm();
    const dispatch = useDispatch();

    useEffect(() => {
        fetchLogout()
            .finally(async () => {
                setFinancialForm(null);
                store.dispatch(logout());
                navigate("/ingresar");
            });
    }, []);

    return <div />;
};
