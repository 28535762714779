/* eslint-disable no-unused-vars */
import React, {
  FC,
  useCallback,
  useEffect,
  useState,
} from "react";
import "./OzonFly.scss";
import * as yup from "yup";
import { FormikHelpers, FormikProvider, useFormik } from "formik";
import { Typography } from "design_system/src";

import Swal from "sweetalert2";
// eslint-disable-next-line import/extensions,import/no-unresolved
import SwiperClass from "swiper/types/swiper-class";
import { ReactComponent as CreditCard } from "design_system/src/static/icons/credit-card-2.svg";

import SimpleStepper from "views/creditCalculation/SimpleStepper/SimpleStepper/SimpleStepper";
import { IStepperItem } from "views/creditCalculation/context/context";
import _ from "lodash";

import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import AlertMessageWithoutVehicle from "components/alertMessageCard/AlertMessageWithoutVehicle";
import AlertMessageWithvehicle from "components/alertMessageCard/AlertMessageWithVehicle";
import { useCreditVerificationSelectedVehicle } from "hooks/useCreditVerificationSelectedVehicle";
import { ValidationIdentityFormEnum } from "enums/validationIdentityForm.enum";
import { FinancialFormStatus } from "models/financialForm.interface";

import { useNavigate } from "react-router-dom";
import {
  fetchNotifyValidateIdentity,
} from "helpers/fetchFormCrediticio";
import useUserVerificationDetails from "hooks/useUserVerificationDetails";
import { UserDocument } from "helpers/fetchCheckIn";
import motoLoading from "static/images/validateIdentity/loading_moto.gif";
import ozoner4 from "static/images/validateIdentity/ozoner4.jpg";
import AlertMessageCard from "components/alertMessageCard/AlertMessageCard";
import { TImages, TVehicle } from "models/vehicle.interface";
import { RootState, store } from "store";
import { getVehicle } from "helpers/fetchVehicles";

import AlertMessage from "components/alertMessage/AlertMessage";

import { useFinancialForm, transformFinancialFormObj } from "hooks/useGetFinancialForm";
import { getBase64 } from "../uploadInput/UploadInput";
import {
  StepOneA,
  StepOneB,
  StepTwoA,
  StepTwoB,
} from "../steps/index";
import { IDocumentsOzoner } from "../hooks/useUploadFiles";

interface Props {
  workDigitalPlatforms: boolean;
  complete?: Function;
  error?: Function;
}

export interface PersonalReference {
  name?: string;
  phone?: string;
  curp?: string;
  relation?: string;
  other?: string;
}

export interface PersonalReferences {
  personal_reference_1: PersonalReference;
  personal_reference_2: PersonalReference;
}

export interface IDocument {
  imageData: any;
  imageFile: Blob | UserDocument;
}

type DocumentType = IDocument | null | undefined;

export interface ValidateIdentityStepperFormValues {
  [ValidationIdentityFormEnum.INDENTITY_STEP]: {
    ine_front: DocumentType;
    ine_back: DocumentType;
  };
  [ValidationIdentityFormEnum.SELFIE_STEP]: {
    selfie: DocumentType;
  },
  [ValidationIdentityFormEnum.ADDRESS_STEP]: {
    proof_of_address?: DocumentType;
  };
  [ValidationIdentityFormEnum.CERTIFICATES_STEP]: {
    bank_certificate_1?: DocumentType;
    bank_certificate_2?: DocumentType;
    bank_certificate_3?: DocumentType;
  };
}

export const SUPPORTED_FORMATS = ["image/jpg", "image/png", "image/jpeg"];

export const DOCUMENTSTOUPDATE = {
  ine_front: ValidationIdentityFormEnum.INE_FRONT,
  ine_back: ValidationIdentityFormEnum.INE_BACK,
  selfie: ValidationIdentityFormEnum.SELFIE,
  proof_of_address: ValidationIdentityFormEnum.PROOF_OF_ADDRESS,
  bank_certificate_1: ValidationIdentityFormEnum.BANK_CERTIFICATE_1,
  bank_certificate_2: ValidationIdentityFormEnum.BANK_CERTIFICATE_2,
  bank_certificate_3: ValidationIdentityFormEnum.BANK_CERTIFICATE_3,
};

export const fetchImageAsBlob = async (
  url: string,
  name: string
): Promise<{
  imageData: string;
  imageFile: File;
  name: string;
}> => {
  try {
    const response = await fetch(url);
    const blob: Blob = await response.blob();
    const file = new File([blob], `${name}.jpg`, {
      type: blob.type,
      lastModified: new Date().getTime(),
    });
    const base64 = await getBase64(file);
    return { imageData: base64, imageFile: file, name };
    // eslint-disable-next-line no-shadow
  } catch (error) {
    throw new Error("Image response was not ok");
  }
};

const validationSchema = yup.object().shape({
  [ValidationIdentityFormEnum.INDENTITY_STEP]: yup.object().shape({
    ine_front: yup
      .mixed()
      .required("Campo requerido")
      .test("fileSize", "El archivo es demasiado grande", (data) => {
        if (data?._id) {
          return true;
        }
        if (!data || !data.imageFile) {
          return false;
        }
        return data.imageFile.size <= 1000000 * 5;
      })
      .test(
        "format",
        "El archivo no corresponde al formato jpg/jpeg o png",
        (value) => {
          if (value?._id) {
            return true;
          }
          if (!value || !value.imageFile || !value.imageFile.type) {
            return false;
          }
          return SUPPORTED_FORMATS.includes(value.imageFile.type);
        }
      ),
    ine_back: yup
      .mixed()
      .required("Campo requerido")
      .test("fileSize", "El archivo es demasiado grande", (data) => {
        if (data?._id) {
          return true;
        }
        if (!data || !data.imageFile) {
          return false;
        }
        return data.imageFile.size <= 1000000 * 5;
      })
      .test(
        "format",
        "El archivo no corresponde al formato jpg/jpeg o png",
        (value) => {
          if (value?._id) {
            return true;
          }
          if (!value || !value.imageFile || !value.imageFile.type) {
            return false;
          }
          return SUPPORTED_FORMATS.includes(value.imageFile.type);
        }
      ),
  }),
  [ValidationIdentityFormEnum.SELFIE_STEP]: yup.object().shape({
    selfie: yup
      .mixed()
      .required("Campo requerido")
      .test("fileSize", "El archivo es demasiado grande", (data) => {
        if (data?._id) {
          return true;
        }
        if (!data || !data.imageFile) {
          return false;
        }
        return data.imageFile.size <= 1000000 * 5;
      })
      .test(
        "format",
        "El archivo no corresponde al formato jpg/jpeg o png",
        (value) => {
          if (value?._id) {
            return true;
          }
          if (!value || !value.imageFile || !value.imageFile.type) {
            return false;
          }
          return SUPPORTED_FORMATS.includes(value.imageFile.type);
        }
      ),
  }),
  [ValidationIdentityFormEnum.ADDRESS_STEP]: yup.object().shape({
    proof_of_address: yup.mixed(),
  }),
  [ValidationIdentityFormEnum.CERTIFICATES_STEP]: yup.object().shape({
    bank_certificate_1: yup.mixed(),
    bank_certificate_2: yup.mixed(),
    bank_certificate_3: yup.mixed(),
  }),
});

export type StepsKeys = keyof typeof validationSchema.fields;

export const validateStep = (
  step: StepsKeys,
  values: ValidateIdentityStepperFormValues
) =>
  // @ts-ignore
  yup.reach(validationSchema, step).isValidSync(values[step]);

export const getFileFromValues = (
  values: ValidateIdentityStepperFormValues,
  documentName: string
) => {
  const conglomerate = {
    ...values[ValidationIdentityFormEnum.INDENTITY_STEP],
    ...values[ValidationIdentityFormEnum.ADDRESS_STEP],
    ...values[ValidationIdentityFormEnum.CERTIFICATES_STEP],
  };
  type Keys = keyof typeof conglomerate;
  return conglomerate[documentName as unknown as Keys]?.imageFile;
};

export const OzonFlyForm: FC<Props> = ({ workDigitalPlatforms, complete, error }) => {
  const [swiperRef, setSwiperRef] = useState<SwiperClass>();
  const [slider, setSlider] = useState({ current: 0 });
  const [loading, setLoading] = useState(false);
  const [creditCalculationData] = useUserVerificationDetails();
  const [activeStep, setActiveStep] = useState(0);
  const [results, setResults] = useUserVerificationDetails();

  const [photos, setPhotos] = useState<TImages[] | undefined>([]);
  const { selectedVehicle, selectedCreditTime } = useSelector(
    (state: RootState) => state.creditVerificationReducer
  );
  const vehicleSolit = useCreditVerificationSelectedVehicle();
  const { user } = useSelector((state: RootState) => state.userReducer);

  const [vehicle, setvehicle] = useState<TVehicle | null>(null);
  const [failedRequests, setFailedRequests] = useState(0);
  const [showAlertMessage, setShowAlertMessage] = useState(true);

  const [openConfirmation, setOpenConfirmation] = React.useState(false);

  const navigate = useNavigate();

  const [financialForm, setFinancialForm] = useFinancialForm();

  useEffect(() => {
    async function fetchVehicle() {
      return getVehicle(selectedVehicle.internalId);
    }
    if (selectedVehicle) {
      fetchVehicle().then((response) => {
        setvehicle(response);
        setPhotos(response?.images);
      });
    }
  }, []);

  const slideTo = (index: any) => {
    if (swiperRef) swiperRef.slideTo(index - 1, 0);
  };
  const onSubmitForm = useCallback(
    async (
      values: ValidateIdentityStepperFormValues,
      helpers: FormikHelpers<ValidateIdentityStepperFormValues>
    ) => {
      try {
        setLoading(true);
          if (financialForm?.status === FinancialFormStatus.REJECTED) {
          navigate("/rechazo-solicitud");
          return;
        }

        await fetchNotifyValidateIdentity(user.email, vehicleSolit?.internalId);
        complete?.();
      } catch (e) {
        setLoading(false);
        setFailedRequests((prevState) => prevState + 1);
        setShowAlertMessage(true);
        error?.();
        if (Array.isArray(e)) {
          Swal.fire({
            title: "Error",
            text: "Tu selfie y tu INE están Borrosas.",
            icon: "error",
          });
          helpers.setFieldError(
            ValidationIdentityFormEnum.INE_FRONT,
            "Cuida que no se vea borrosa la imagen"
          );
          helpers.setFieldError(
            ValidationIdentityFormEnum.INE_FRONT,
            "Cuida que no se vea borrosa la imagen"
          );
          helpers.setFieldError(
            ValidationIdentityFormEnum.SELFIE,
            "Cuida que tu cara esté centrada"
          );
        } else {
          Swal.fire({
            text: "No se pudo validar la información.",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      }
    },
    [complete]
  );

  const handleClose = () => setOpenConfirmation(!openConfirmation);

  const renderModalTitleBank = () => (
    <Typography scale="medium" weight="400" textColor="neutral_900">
      ¿Seguro que{" "}
      <span style={{ fontWeight: "bolder" }}>
        {" "}
        quieres continuar sin subir los Estados de cuenta?
      </span>{" "}
    </Typography>
  );
  const renderModalSubTitleBank = () => (
    <Typography scale="xsmall" weight="400" textColor="neutral_900">
      Recuerda que te los pediremos al darte tu moto{" "}
    </Typography>
  );

  const renderModalTitleAddress = () => (
    <Typography scale="medium" weight="400" textColor="neutral_900">
      ¿Seguro que{" "}
      <span style={{ fontWeight: "bolder" }}>
        {" "}
        quieres continuar sin subir el comprobante de domicilio?
      </span>{" "}
    </Typography>
  );

  const renderModalSubTitleAddress = () => (
    <Typography scale="xsmall" weight="400" textColor="neutral_900">
      {" "}
    </Typography>
  );

  const form = useFormik<ValidateIdentityStepperFormValues>({
    initialValues: {
      [ValidationIdentityFormEnum.INDENTITY_STEP]: {
        ine_front: null,
        ine_back: null,
      },
      [ValidationIdentityFormEnum.SELFIE_STEP]: {
        selfie: null,
      },
      [ValidationIdentityFormEnum.ADDRESS_STEP]: {
        proof_of_address: null,
      },
      [ValidationIdentityFormEnum.CERTIFICATES_STEP]: {
        bank_certificate_1: null,
        bank_certificate_2: null,
        bank_certificate_3: null,
      },
    },
    validationSchema,
    onSubmit: onSubmitForm,
  });

  useEffect(() => {
      if (financialForm) {
          const { documents } = financialForm;
          documents.forEach((element: IDocumentsOzoner) => {
              const documentToUpdate = DOCUMENTSTOUPDATE[element.name as keyof typeof DOCUMENTSTOUPDATE];
              if (documentToUpdate) {
                  if (documentToUpdate && form.values[documentToUpdate as keyof typeof form.values] !== null) {
                      fetchImageAsBlob(element.url, element.name).then((imageFile) => {
                          form.setFieldValue(documentToUpdate, imageFile, true);
                      });
                  }
              }
          });

          Object.keys(DOCUMENTSTOUPDATE).filter((e) => !documents.map((i) => i.name).includes(e)).forEach((doc) => {
              const documentToUpdate = DOCUMENTSTOUPDATE[doc as keyof typeof DOCUMENTSTOUPDATE];
              form.setFieldValue(documentToUpdate, null, true);
          });
      }
  }, [financialForm, ]);

  useEffect(() => {
      setFinancialForm(transformFinancialFormObj(user));
  }, [user]);

  const initialStepComponentsArr: Array<IStepperItem>[] = [
    [
      {
        component: <StepOneA />,
        stepTitle: "Verificación de identidad",
        validationKey: ValidationIdentityFormEnum.INDENTITY_STEP,
      },
      {
        component: <StepOneB />,
        validationKey: ValidationIdentityFormEnum.SELFIE_STEP,
      },
    ],
    [
      {
        component: <StepTwoA />,
        stepTitle: "Documentos de validación",
        validationKey: ValidationIdentityFormEnum.ADDRESS_STEP,
        stepModalConfig: {
          icon: <CreditCard />,
          handleOpen: () => !_.get(form.values, ValidationIdentityFormEnum.PROOF_OF_ADDRESS),
          handleClose,
          title: renderModalTitleAddress(),
          subtitle: renderModalSubTitleAddress(),
        },
      },
      {
        component: <StepTwoB workDigitalPlatforms={workDigitalPlatforms}/>,
        validationKey: ValidationIdentityFormEnum.CERTIFICATES_STEP,
        stepModalConfig: {
          icon: <CreditCard />,
          handleClose,
          title: renderModalTitleBank(),
          subtitle: renderModalSubTitleBank(),
        },
      },
    ],
  ];
  const [stepComponentsArr, setStepComponentsArr] = useState(
    initialStepComponentsArr
  );

  useEffect(() => {
    const newArray = initialStepComponentsArr.map((stepArray: any) => {
      return stepArray.map(
        (step: {
          [x: string]: any;
          validationKey?: any;
          stepModalConfig?: any;
        }) => {
          const { stepModalConfig, ...rest } = step;
          const { values } = form;

          if (
            step.validationKey === ValidationIdentityFormEnum.CERTIFICATES_STEP &&
            _.get(values, ValidationIdentityFormEnum.BANK_CERTIFICATE_1) !== null &&
            _.get(values, ValidationIdentityFormEnum.BANK_CERTIFICATE_2) !== null &&
            _.get(values, ValidationIdentityFormEnum.BANK_CERTIFICATE_3) !== null
          ) {
            return rest;
          }

          if (
            step.validationKey === ValidationIdentityFormEnum.ADDRESS_STEP &&
            _.get(values, ValidationIdentityFormEnum.PROOF_OF_ADDRESS) !== null
          ) {
            return rest;
          }
          return step;
        }
      );
    });
    setStepComponentsArr(newArray);
  }, [form.values]);

  const renderValidateIdentityForm = () => {
    return (
      <FormikProvider value={form}>
        <div className="display_flex flex_col_reverse_mobile flex_gap_xl_mobile m_xl flex_justify_center">
          <div className="flex_basis_0 flex_grow_4 flex_center_col p_r_xxxl_desktop m_b_xxl_mobile">
            <Typography weight="600" scale="heading3" className="">
              Termina tu proceso,
            </Typography>
            <Typography weight="600" scale="heading3" textColor="primary_300">
              validando tu identidad
            </Typography>
            <Typography weight="600" scale="heading3" className="m_b_xxxl">
              y consigue tu moto
            </Typography>
            <div className="dso_card bg_neutral_0 p_x_xxl p_y_md flex_center_col">
              <Typography
                weight="400"
                scale="small"
                className="m_b_lg w_60_per"
              >
                {"Se parte de "}
                <Typography weight="600" scale="small" component="span">
                  {"más de 2000 Ozoners "}
                </Typography>
                que ya ruedan con una moto OZON
              </Typography>
              <img src={ozoner4} alt="moto" className="w_100_per" />
              <div className="custom-slider">
                {Array(4).map((_e, index) => (
                  <div
                    className={`slider-point ${
                      slider.current === index ? "active" : ""
                    }`}
                    onClick={() => slideTo(index + 1)}
                    key={`heros-${index}`}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="flex_basis_0 flex_grow_5 pos_relative">
            {failedRequests > 0 && (
              <AlertMessage
                severity="warning"
                message={`Validación de identidad ${failedRequests}/3 intentos`}
                showAlert={showAlertMessage}
                setShowAlert={setShowAlertMessage}
              />
            )}
            {loading && (
              <div className="identity_loading flex_center_col">
                <Typography
                  weight="600"
                  scale="medium"
                  textColor="neutral_0"
                  className="m_b_xxxs"
                >
                  Cargando tus datos
                </Typography>
                <img src={motoLoading} alt="" />
              </div>
            )}
            <div className="dso_card br_lg overflow_hidden p_t_md">
              <SimpleStepper
                validateStep={validateStep}
                stepperComponents={stepComponentsArr}
                onSubmitForm={form.submitForm}
                loading={loading}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
              />
              <div className="p_y_xs p_x_md">
                <Typography scale="xsmall" weight="400" className="text_center">
                  *Sí detectamos información{" "}
                  <span className="text_red_300">falsa</span> o{" "}
                  <span className="text_red_300">engañosa</span> tu solicitud
                  será inmediatamente{" "}
                  <span className="text_red_300">rechazada</span>.
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </FormikProvider>
    );
  };

  const renderFailedAttemptsElement = () => {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={7}>
          <AlertMessageCard />
        </Grid>
        <Grid item xs={12} sm={6} md={5}>
          {vehicle ? (
            <AlertMessageWithvehicle vehicle={vehicle} />
          ) : (
            <AlertMessageWithoutVehicle />
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <div style={{ margin: "0 0 5vh 0" }}>
      {failedRequests >= 1
        ? renderFailedAttemptsElement()
        : renderValidateIdentityForm()}
    </div>
  );
};
