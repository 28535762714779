/* eslint-disable prefer-destructuring */
import { Dispatch, SetStateAction } from "react";
import { FinancialFormStatus } from "models/financialForm.interface";
import { IDocumentsOzoner } from "views/ozonFly/hooks/useUploadFiles";
import useLocalStorage from "./useLocalStorage";

export type SetValue<T> = Dispatch<SetStateAction<T>>;

interface IReferenceFinancialForm {
    name: string;
    other: string;
    phone: string;
    relation: string;
}

interface IFinancialForm {
    _id: string;
    status: FinancialFormStatus;
    documents: IDocumentsOzoner[];
    userInfoStep: any;
    vehicle?: any;
    vehicleAvailable?: boolean;
    maxWeekQuota?: number;
    personal_references?: {
        personal_reference_1: IReferenceFinancialForm;
        personal_reference_2: IReferenceFinancialForm;
    };
    appointment?: {
        scheduledAt: string;
    };
    statusHistoric: any[];
    createdAt: string;
    workInDigitalPlatforms: boolean;
    isAccountActive?: boolean;
    creditTime?: number;
    deposit?: number;
}

export const useFinancialForm = () : [IFinancialForm | null, SetValue<IFinancialForm | null>] => {
    const [data, setData] = useLocalStorage<IFinancialForm | null>("current-financial-form", null);
    return [data, setData];
};

export const getCurrentForm = (financialForms: IFinancialForm[]) => {
    const activeFormsStatus = [
        FinancialFormStatus.PENDING,
        FinancialFormStatus.APPROVED,
        FinancialFormStatus.IN_VALIDATION,
        FinancialFormStatus.SCHEDULED,
        FinancialFormStatus.COMPLETED,
    ];

    const activeForms = financialForms
        .filter((form) => activeFormsStatus.includes(form.status))
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

    let currentForm;

    if (activeForms && activeForms.length > 0) {
        currentForm = activeForms[0];
    } else {
        const inactiveFormsStatus = [
            FinancialFormStatus.REJECTED
        ];

        currentForm = financialForms
            .filter((form) => inactiveFormsStatus.includes(form.status))
            .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())[0];
    }

    return currentForm;
};

export const transformFinancialFormObj = (obj: any): IFinancialForm | null => {
    const currentFinancialForm = getCurrentForm(obj.financialForm);
    const vehicle = obj.vehicle ?? null;

    return currentFinancialForm ? {
        ...currentFinancialForm,
        vehicle,
        documents: [
            ...currentFinancialForm.documents,
            ...obj.documents,
        ]
    } : null;
};