/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */

import React, { FC, useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { useFormikContext } from "formik";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import moment from "moment";
import {
  Input,
  Typography,
  OzonSelect,
  Button,
} from "design_system/src";
import { useDispatch } from "react-redux";
import { ReactComponent as Moto } from "design_system/src/static/icons/motorcycle.svg";
import { ReactComponent as Email } from "design_system/src/static/icons/email.svg";
import { FormControl } from "@mui/material";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core";
import { ReactComponent as SuccessPlan } from "design_system/src/static/icons/success-plan.svg";
import { ReactComponent as FailedPlan } from "design_system/src/static/icons/failed-plan.svg";
import { ReactComponent as Chat } from "design_system/src/static/icons/group-chat.svg";
import { ReactComponent as Baby } from "design_system/src/static/icons/baby.svg";
import { ReactComponent as Profile } from "design_system/src/static/icons/profile.svg";
import { ReactComponent as Curp } from "design_system/src/static/icons/user-card-2.svg";
import { ReactComponent as Calendar } from "design_system/src/static/icons/calendar.svg";
import { ReactComponent as Groups } from "design_system/src/static/icons/groups.svg";
import { ReactComponent as Building } from "design_system/src/static/icons/building.svg";
import { ReactComponent as CoupleLove } from "design_system/src/static/icons/couple-love.svg";
import { ReactComponent as Boy } from "design_system/src/static/icons/boy.svg";
import { ReactComponent as Phone } from "design_system/src/static/icons/phone.svg";
import { ReactComponent as Map } from "design_system/src/static/icons/map.svg";
import { ReactComponent as Mail } from "design_system/src/static/icons/mail.svg";
import { ReactComponent as NumbE } from "design_system/src/static/icons/numbered-list.svg";

import ReactGA from "react-ga4";
import { fetchValidateCurp } from "helpers/fetchCheckIn";
import { CalculationStepperFormValues } from "../CreditCalculationStepperView";
import IncomeCard from "../../../components/creditCalculationStepper/incomeStep/incomeCard/IncomeCard";
import FamilyCard from "../../../components/creditCalculationStepper/dependantsStep/familyCard/FamilyCard";

import CustomInputTitle from "../../../components/customInputTitle/CustomInputTitle";
import {
  getDayOptions,
  getMonthsNamesOptions,
  getYearOptions,
} from "../../../helpers/dateUtils";
import AssetCard, { assetEnum } from "../../../components/assetCard/assetCard";

import NoneIcon from "../../../static/icons/codeBar.png";
import HomeIcon from "../../../static/icons/houseIcon.png";
import CarIcon from "../../../static/icons/carIcon.svg";
import MotoIcon from "../../../static/icons/motoIcon.png";
import CubeIcon from "../../../static/icons/cubeIcon .png";
import CustomCheckBox from "../../../components/customCheckBox/CustomCheckBox";
import { FinancialFormFieldsEnum } from "../../../enums/financialFormFields.enum";
import { ModalAdress } from "../modalAddress/ModalAdress";
import { fetchByPostalCode } from "../modalAddress/copomex";
import { ModalConfirmAddress } from "../modalConfirmAddress/ModalConfirmAddress";
import { ModalTermsAndConditions } from "../modalTermsAndConditions/ModalTermsAndConditions";


type StepProps = {
  subTitle?: string;
};

export const StepZero: React.FC<StepProps> = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const {
    values,
    handleChange,
    errors,
    handleBlur,
    getFieldMeta,
    setFieldValue,
    setFieldError,
  } = useFormikContext<CalculationStepperFormValues>();

  const onBlurEvent = async (event: any) => {
    handleBlur(event);
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleCheckboxChange = useCallback(() => {
    setFieldValue(FinancialFormFieldsEnum.AUTH_DATA_TREATMENT, true);
    setOpen(false);
  }, [setFieldValue]);

  const onCheckboxChange = useCallback(
    (e) => {
      // eslint-disable-next-line default-case
      switch (e.target.value) {
        case "1":
          setFieldValue(FinancialFormFieldsEnum.AUTH_DATA_TREATMENT, e.target.checked);
          break;
      }
    },
    [setFieldValue]
  );

  return (
    <div className="flex_1 p_y_xxl p_x_md_mobile">
      <div className="w_60_per_desktop m_x_auto">
        <Typography
          weight="400"
          scale="medium"
          className="text_center m_b_lg"
        >
          <span style={{ fontWeight: "bold" }}>
            ¡Escribe tu correo electrónico
          </span>{" "}
          y da los primeros pasos para adquirir la moto de tus
          sueños!
        </Typography>
        <Input
          name={FinancialFormFieldsEnum.EMAIL}
          placeholder="Escribe tu correo aquí"
          onBlur={(e: any) => onBlurEvent(e)}
          onChange={handleChange}
          value={_.get(values, FinancialFormFieldsEnum.EMAIL)}
          title="Correo"
          icon={<Email />}
          type="text"
          error={
            !!_.get(errors, FinancialFormFieldsEnum.EMAIL) &&
            getFieldMeta(FinancialFormFieldsEnum.EMAIL).touched
          }
          subtitle={
            getFieldMeta(FinancialFormFieldsEnum.EMAIL).touched
              ? (_.get(errors, FinancialFormFieldsEnum.EMAIL) as string)
              : undefined
          }
        />
          <Input
            name="extra"
            checked={_.get(values, FinancialFormFieldsEnum.AUTH_DATA_TREATMENT)}
            onChange={onCheckboxChange}
            className="border_neutral_700 center_x w_fit"
            title=""
            options={[
              {
                label: (
                <>
                  Autorizo el uso de mis datos de acuerdo al{" "}
                  <Link to="/aviso-de-privacidad" target="_blank">
                    Aviso de Privacidad
                  </Link>{" "}
                  y acepto los
                  <span
                    onClick={handleOpen}
                    style={{
                      cursor: "pointer",
                      color: "blue",
                      textDecoration: "underline",
                    }}
                  >
                    {" "}
                    Términos y Condiciones.
                  </span>
                </>
                ),
                value: 1,
              },
            ]}
            type="checkbox"
          />
      </div>
      <ModalTermsAndConditions
        open={open}
        setOpen={handleOpen}
        handleCheckboxChange={handleCheckboxChange}
      />
  </div>
  );
};

export const StepOne: React.FC<StepProps> = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [curp, setCurp] = useState<string>("");
  const {
    values,
    handleChange,
    errors,
    handleBlur,
    getFieldMeta,
    setFieldValue,
    setFieldError,
  } = useFormikContext<CalculationStepperFormValues>();

  const populateInfoFromCurp = (): void => {
    const currentCURP = _.get(values, FinancialFormFieldsEnum.CURP);
    const dateTemp = currentCURP.substring(4, 10);

    moment.locale("es");
    // const age = moment().diff(moment(dateTemp, "YYMMDD"), "years", false);
    const yearOfBirth = moment(dateTemp, "YYMMDD").format("YYYY");
    const monthOfBirth = moment(dateTemp, "YYMMDD").format("MMMM");
    const dayOfBirth = moment(dateTemp, "YYMMDD").format("DD");
    const gender =
      currentCURP.toUpperCase().substring(10, 11) === "H"
        ? "Masculino"
        : "Femenino";

    // setFieldValue(FinancialFormFieldsEnum.AGE, age);
    setFieldValue(FinancialFormFieldsEnum.GENDER, gender);
    setFieldValue(FinancialFormFieldsEnum.YEAR, yearOfBirth);
    setFieldValue(FinancialFormFieldsEnum.DAY, dayOfBirth);
    setFieldValue(FinancialFormFieldsEnum.MONTH, monthOfBirth);
  };

  useEffect(() => {
    ReactGA.event("VIEW_Financial_info", {
      category: "VIEW_Financial_info",
      label: "page view to the basic personal information view",
    });
    const cachedCurp = localStorage.getItem("curpModal");
    if (cachedCurp) {
      populateInfoFromCurp();
    }
  }, []);

  const onBlurEvent = async (event: any) => {
    setCurp(_.get(values, FinancialFormFieldsEnum.CURP));
    handleBlur(event);
    if (!_.get(errors, FinancialFormFieldsEnum.CURP)) {
      populateInfoFromCurp();
    }
  };

  return (
    <div className="animate__animated animate__fadeIn">
      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}>
            <Input
              type="text"
              title="Nombres"
              name={FinancialFormFieldsEnum.NAME}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Escribe tu nombre aquí"
              icon={<Boy className="primary_300" />}
              value={_.get(values, FinancialFormFieldsEnum.NAME)}
              error={
                !!_.get(errors, FinancialFormFieldsEnum.NAME) &&
                getFieldMeta(FinancialFormFieldsEnum.NAME).touched
              }
              subtitle={
                getFieldMeta(FinancialFormFieldsEnum.NAME).touched
                  ? (_.get(errors, FinancialFormFieldsEnum.NAME) as string)
                  : undefined
              }
            />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <Input
              type="text"
              title="1er. Apellido"
              name={FinancialFormFieldsEnum.FIRST_LAST_NAME}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Escribe tu apellido paterno"
              icon={<Boy className="primary_300" />}
              value={_.get(values, FinancialFormFieldsEnum.FIRST_LAST_NAME)}
              error={
                !!_.get(errors, FinancialFormFieldsEnum.FIRST_LAST_NAME) &&
                getFieldMeta(FinancialFormFieldsEnum.FIRST_LAST_NAME).touched
              }
              subtitle={
                getFieldMeta(FinancialFormFieldsEnum.FIRST_LAST_NAME).touched
                  ? (_.get(
                      errors,
                      FinancialFormFieldsEnum.FIRST_LAST_NAME
                    ) as string)
                  : undefined
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              type="text"
              title="2do. apellido"
              name={FinancialFormFieldsEnum.SECOND_LAST_NAME}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Escribe tu apellido materno"
              icon={<Boy className="primary_300" />}
              value={_.get(values, FinancialFormFieldsEnum.SECOND_LAST_NAME)}
              error={
                !!_.get(errors, FinancialFormFieldsEnum.SECOND_LAST_NAME) &&
                getFieldMeta(FinancialFormFieldsEnum.SECOND_LAST_NAME).touched
              }
              subtitle={
                getFieldMeta(FinancialFormFieldsEnum.SECOND_LAST_NAME).touched
                  ? (_.get(
                      errors,
                      FinancialFormFieldsEnum.SECOND_LAST_NAME
                    ) as string)
                  : undefined
              }
            />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item sm={6} xs={12}>
            <div className="dso_input_cont">
              <div className="dso_input_title m_b_none">
                <div className="dso_input_img">
                  <Phone />
                </div>
                <div className="dso_input_text">Celular</div>
              </div>
            </div>
            <div className="w_100_per display_flex flex_align_start flex_gap_xs">
              <div className="display_flex flex_center flex_gap_xxs w_30_per bg_neutral_500 m_y_md_mobile m_y_sm_desktop p_y_sm_desktop  p_y_xs_mobile br_xxs">
                <img
                  src="https://flagcdn.com/w80/mx.png"
                  alt="mx"
                  width="36"
                  height="20"
                />
                <Typography scale="xsmall" weight="600" className="">
                  +52
                </Typography>
              </div>
              <div className="w_70_per">
                <Input
                  title=""
                  type="number"
                  name={FinancialFormFieldsEnum.PHONE}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="1234567890"
                  value={_.get(values, FinancialFormFieldsEnum.PHONE)}
                  error={
                    !!_.get(errors, FinancialFormFieldsEnum.PHONE) &&
                    getFieldMeta(FinancialFormFieldsEnum.PHONE).touched
                  }
                  subtitle={
                    getFieldMeta(FinancialFormFieldsEnum.PHONE).touched
                      ? (_.get(errors, FinancialFormFieldsEnum.PHONE) as string)
                      : undefined
                  }
                />
              </div>
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              type="text"
              title="CURP"
              name={FinancialFormFieldsEnum.CURP}
              icon={<Curp className="custom-input-color" />}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                e.target.value = e.target.value.toUpperCase();
                handleChange(e);
              }}
              onBlur={(e: any) => onBlurEvent(e)}
              placeholder="Escribe tu curp"
              value={_.get(values, FinancialFormFieldsEnum.CURP)}
              error={
                !!_.get(errors, FinancialFormFieldsEnum.CURP) &&
                getFieldMeta(FinancialFormFieldsEnum.CURP).touched
              }
              subtitle={
                getFieldMeta(FinancialFormFieldsEnum.CURP).touched
                  ? (_.get(errors, FinancialFormFieldsEnum.CURP) as string)
                  : undefined
              }
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export const StepOneAndHalf: React.FC<StepProps> = () => {
  const [neighborhoodOptions, setNeighborhoodOptions] = useState([]);

  const {
    values,
    handleChange,
    errors,
    handleBlur,
    getFieldMeta,
    setFieldValue,
    setFieldError,
  } = useFormikContext<CalculationStepperFormValues>();

  const handleChangeCp = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    if (e.target.value.length === 5) {
      setFieldValue(FinancialFormFieldsEnum.STATE, "");
      setFieldValue(FinancialFormFieldsEnum.DELEGATION, "");
      setFieldValue(FinancialFormFieldsEnum.NEIGHBORHOOD, "");
      fetchByPostalCode(e.target.value)
        .then((response) => {
          const { data } = response;
          if (!data.error) {
            setFieldValue(FinancialFormFieldsEnum.STATE, data.response.estado);
            setFieldValue(
              FinancialFormFieldsEnum.DELEGATION,
              data.response.municipio
            );
            setNeighborhoodOptions(data.response.asentamiento);
          }
        })
        .catch((err) => {
          setFieldError(FinancialFormFieldsEnum.CP, "El código postal no existe");
          setNeighborhoodOptions([]);
          setFieldError("zipcode", "¡No se encontró el código postal!");
        });
    }
  };

  useEffect(() => {
    ReactGA.event("VIEW_Financial_zipcode", {
      category: "VIEW_Financial_zipcode",
      label: "page view to the zip code address fill",
    });
  }, []);

  return (
    <div className="animate__animated animate__fadeIn">
      <Grid container rowSpacing={0} columnSpacing={2}>
        <Grid item xs={12}>
          <Input
            type="text"
            title="Código postal"
            name={FinancialFormFieldsEnum.CP}
            onChange={(e) => handleChangeCp(e)}
            onBlur={handleBlur}
            placeholder="Escribe el código postal"
            icon={<Mail className="primary_300" />}
            value={_.get(values, FinancialFormFieldsEnum.CP)}
            error={
              !!_.get(errors, FinancialFormFieldsEnum.CP) &&
              getFieldMeta(FinancialFormFieldsEnum.CP).touched
            }
            subtitle={
              getFieldMeta(FinancialFormFieldsEnum.CP).touched
                ? (_.get(errors, FinancialFormFieldsEnum.CP,"") as string)
                : undefined
            }
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Input
            type="select"
            title="Colonia*"
            name={FinancialFormFieldsEnum.NEIGHBORHOOD}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Seleciona la colonia"
            options={neighborhoodOptions}
            icon={<Map className="primary_300" />}
            value={_.get(values, FinancialFormFieldsEnum.NEIGHBORHOOD)}
            error={
              !!_.get(errors, FinancialFormFieldsEnum.NEIGHBORHOOD) &&
              getFieldMeta(FinancialFormFieldsEnum.NEIGHBORHOOD).touched
            }
            subtitle={
              getFieldMeta(FinancialFormFieldsEnum.NEIGHBORHOOD).touched
                ? (_.get(
                    errors,
                    FinancialFormFieldsEnum.NEIGHBORHOOD
                    ,"") as string)
                : undefined
            }
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <Input
            type="text"
            title="Calle*"
            name={FinancialFormFieldsEnum.STREET}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Escribe la calle"
            icon={<Map className="primary_300" />}
            value={_.get(values, FinancialFormFieldsEnum.STREET)}
            error={
              !!_.get(errors, FinancialFormFieldsEnum.STREET) &&
              getFieldMeta(FinancialFormFieldsEnum.STREET).touched
            }
            subtitle={
              getFieldMeta(FinancialFormFieldsEnum.STREET).touched
                ? (_.get(errors, FinancialFormFieldsEnum.STREET,"") as string)
                : undefined
            }
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Input
            type="number"
            title="Número Exterior*"
            name={FinancialFormFieldsEnum.EXT_NUMBER}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Escribe el número exterior"
            icon={<NumbE className="primary_300" />}
            value={_.get(values, FinancialFormFieldsEnum.EXT_NUMBER)}
            error={
              !!_.get(errors, FinancialFormFieldsEnum.EXT_NUMBER) &&
              getFieldMeta(FinancialFormFieldsEnum.EXT_NUMBER).touched
            }
            subtitle={
              getFieldMeta(FinancialFormFieldsEnum.EXT_NUMBER).touched
                ? (_.get(errors, FinancialFormFieldsEnum.EXT_NUMBER,"") as string)
                : undefined
            }
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Input
            type="number"
            title="Número Interior"
            name={FinancialFormFieldsEnum.INT_NUMBER}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Escribe el número interior"
            icon={<NumbE className="primary_300" />}
            value={_.get(values, FinancialFormFieldsEnum.INT_NUMBER)}
            error={
              !!_.get(errors, FinancialFormFieldsEnum.INT_NUMBER) &&
              getFieldMeta(FinancialFormFieldsEnum.INT_NUMBER).touched
            }
            subtitle={
              getFieldMeta(FinancialFormFieldsEnum.INT_NUMBER).touched
                ? (_.get(errors, FinancialFormFieldsEnum.INT_NUMBER) as string)
                : undefined
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            scale="small"
            weight="600"
            textColor="neutral_700"
            className="m_b_md"
          >
            Resumen
          </Typography>
          <Typography
            scale="small"
            weight="600"
            textColor="neutral_700"
            className="w_100_per p_y_xs p_x_md bg_neutral_300 br_xxs border_1 border_neutral_500 shadow_soft border_solid m_b_md"
          >
            {`${_.get(values, FinancialFormFieldsEnum.STREET) || ""} ${
              _.get(values, FinancialFormFieldsEnum.EXT_NUMBER) || ""
            } ${_.get(values, FinancialFormFieldsEnum.INT_NUMBER) || ""}, ${
              _.get(values, FinancialFormFieldsEnum.NEIGHBORHOOD) || ""
            }, ${_.get(values, FinancialFormFieldsEnum.DELEGATION) || ""}, ${
              _.get(values, FinancialFormFieldsEnum.STATE) || ""
            }, MEX, C.P ${_.get(values, FinancialFormFieldsEnum.CP) || ""}`}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export const StepTwo: React.FC<StepProps> = () => {
  const {
    values,
  } = useFormikContext<CalculationStepperFormValues>();

  useEffect(() => {
    ReactGA.event("VIEW_Financial_income", {
      category: "VIEW_Financial_income",
      label: "page view to the income and outcome information view",
    });
  }, []);
  return (
    <div className="animate__animated animate__fadeIn">
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item sm={6} xs={12}>
          <IncomeCard
            icon={<SuccessPlan className="text_primary_300" />}
            placeholder="Escribe tu ingreso promedio mensual"
            label={
              <Typography scale="medium" weight="400" textColor="neutral_600" className="text_center">
                ¿Cuales son tus{" "}
                <Typography
                  component="span"
                  scale="medium"
                  weight="600"
                  textColor="neutral_800"
                >
                  ingresos promedio mensual?
                </Typography>{" "}
              </Typography>
            }
            initValue={_.get(values, FinancialFormFieldsEnum.MONTHLY_INCOME)}
            name={FinancialFormFieldsEnum.MONTHLY_INCOME}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <IncomeCard
            icon={<FailedPlan className="text_primary_300" />}
            placeholder="Escribe tu egreso promedio mensual"
            label={
              <Typography scale="medium" weight="400" textColor="neutral_600" className="text_center">
                ¿Cuales son tus{" "}
                <Typography
                  component="span"
                  scale="medium"
                  weight="600"
                  textColor="neutral_800"
                >
                  gastos promedio mensual?
                </Typography>{" "}
              </Typography>
            }
            initValue={_.get(values, FinancialFormFieldsEnum.MONTHLY_OUTCOME)}
            name={FinancialFormFieldsEnum.MONTHLY_OUTCOME}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export const StepThree: React.FC<StepProps> = () => {
  useEffect(() => {
    ReactGA.event("VIEW_Financial_dependants", {
      category: "VIEW_Financial_dependants",
      label: "page view to the dependants information view",
    });
  }, []);

  return (
    <div className="animate__animated animate__fadeIn">
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <div className="title-container  m_b_md">
            <Typography scale="large" weight="600" textColor="neutral_800">
              Dependientes
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FamilyCard
            name={FinancialFormFieldsEnum.CHILDREN_COUNT}
            icon={<Baby className="text_primary_300" />}
            label="¿Cuantos hijos tienes?"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FamilyCard
            name={FinancialFormFieldsEnum.DEPENDANTS_COUNT}
            icon={<Chat className="text_primary_300" />}
            label="¿Cuantas personas dependen de ti económicamente?"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export const StepThreeAndHalf: React.FC<StepProps> = () => {
  const { values, handleChange, errors, handleBlur, getFieldMeta } =
    useFormikContext<CalculationStepperFormValues>();

    const activityOptions = [
      { label: "Empleado", value: "empleado" },
      { label: "Repartidor", value: "repartidor" },
      { label: "Comerciante", value: "comerciante" },
      { label: "Conductor", value: "conductor" },
      { label: "Conductor repartidor", value: "conductorRepartidor" },
      { label: "Ayudante general", value: "ayudanteGeneral" },
      { label: "Seguridad", value: "seguridad" },
      { label: "Independiente", value: "independiente" },
      { label: "Estudiante", value: "estudiante" },
      { label: "Policia", value: "policia" },
      { label: "Servidor público", value: "servidorPublico" },
      { label: "Enfermero", value: "enfermero" },
      { label: "Mecánico", value: "mecanico" },
      { label: "Otro", value: "otro" },
    ];

    const educationOptions = [
    { label: "Primaria", value: "Primaria" },
    { label: "Secundaria", value: "Secundaria" },
    { label: "Preparatoria", value: "Preparatoria" },
    { label: "Tecnicatura", value: "Tecnicatura" },
    { label: "Licenciatura", value: "Licenciatura" },
    { label: "Maestria", value: "Maestria" },
  ];
  useEffect(() => {
    ReactGA.event("VIEW_Financial_occupation", {
      category: "VIEW_Financial_occupation",
      label: "page view to the occupation information view",
    });
  }, []);
  return (
    <div className="animate__animated animate__fadeIn">
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <div className="title-container  m_b_md">
            <Typography scale="large" weight="600" textColor="neutral_800">
              Empleo
            </Typography>
          </div>
        </Grid>
        <Grid item sm={6} xs={12}>
          <div className="dso_input_cont">
            <div className="dso_input_title">
              <div className="dso_input_img">
                <Groups className="custom-input-color" />
              </div>
              <div className="dso_input_text">¿Cuál es tu ocupación?</div>
            </div>
            <FormControl sx={{ minWidth: "100%" }} size="small">
              <OzonSelect
                itemsLabel="¿Cuál es tu ocupación?"
                fieldName={FinancialFormFieldsEnum.ECONOMIC_ACTIVITY_VALUE}
                options={activityOptions}
                handleBlur={handleBlur}
                handleChange={handleChange}
                values={values}
                getFieldMeta={getFieldMeta}
                errors={errors}
              />
            </FormControl>
          </div>
        </Grid>
        <Grid item sm={6} xs={12}>
          {_.get(values, FinancialFormFieldsEnum.ECONOMIC_ACTIVITY_VALUE) === "otro" && <Input
            type="text"
            title="Describe tu ocupación"
            name={FinancialFormFieldsEnum.ECONOMIC_ACTIVITY_DESCRIPTION}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Escribe tu ocupación aquí"
            icon={<Groups className="primary_300" />}
            value={_.get(values, FinancialFormFieldsEnum.ECONOMIC_ACTIVITY_DESCRIPTION)}
            error={
              !!_.get(errors, FinancialFormFieldsEnum.ECONOMIC_ACTIVITY_DESCRIPTION) &&
              getFieldMeta(FinancialFormFieldsEnum.ECONOMIC_ACTIVITY_DESCRIPTION).touched
            }
            subtitle={
              getFieldMeta(FinancialFormFieldsEnum.ECONOMIC_ACTIVITY_DESCRIPTION).touched
                ? (_.get(
                    errors,
                    FinancialFormFieldsEnum.ECONOMIC_ACTIVITY_DESCRIPTION
                    ,"") as string)
                : undefined
            }
          />}
        </Grid>
        <Grid item sm={6} xs={12}>
          <Input
            type="text"
            title="Nombre de la empresa"
            name={FinancialFormFieldsEnum.COMPANY_NAME}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Escribe el nombre de la empresa"
            icon={<Groups className="primary_300" />}
            value={_.get(values, FinancialFormFieldsEnum.COMPANY_NAME)}
            error={
              !!_.get(errors, FinancialFormFieldsEnum.COMPANY_NAME) &&
              getFieldMeta(FinancialFormFieldsEnum.COMPANY_NAME).touched
            }
            subtitle={
              getFieldMeta(FinancialFormFieldsEnum.COMPANY_NAME).touched
                ? (_.get(
                    errors,
                    FinancialFormFieldsEnum.COMPANY_NAME
                  ,"") as string)
                : undefined
            }
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <div className="dso_input_cont">
            <div className="dso_input_title">
              <div className="dso_input_img">
                <Groups className="custom-input-color" />
              </div>
              <div className="dso_input_text">Nivel de estudios</div>
            </div>
            <FormControl sx={{ minWidth: "100%" }} size="small">
              <OzonSelect
                itemsLabel="Nivel de estudios"
                fieldName={FinancialFormFieldsEnum.EDUCATIONAL_LEVEL}
                options={educationOptions}
                handleBlur={handleBlur}
                handleChange={handleChange}
                values={values}
                getFieldMeta={getFieldMeta}
                errors={errors}
              />
            </FormControl>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export const StepFour: React.FC<StepProps> = () => {
  useEffect(() => {
    ReactGA.event("VIEW_Financial_civilstatus", {
      category: "VIEW_Financial_civilstatus",
      label: "page view to the civil status information view",
    });
  }, []);
  const useStyles = makeStyles({
    listContainer: {
      display: "flex",
      flexDirection: "column",
      height: "auto",
    },
  });

  const classes = useStyles();

  const {
    values,
    handleChange,
    errors,
    handleBlur,
    getFieldMeta,
    setFieldValue,
  } = useFormikContext<CalculationStepperFormValues>();

  const civilStatus = [
    { label: "Solter@", value: "Solter@" },
    { label: "Casad@", value: "Casad@" },
    { label: "Union Libre", value: "Union Libre" },
    { label: "Devorciad@", value: "Devorciad@" },
    { label: "Viud@", value: "Viud@" },
  ];
  const liveAlone = "Solo (a)";
  const livesWith = [
    liveAlone,
    "Esposo (a)",
    "Amigos ",
    "Padres ",
    "Pareja estable",
    "Hermanos ",
    "Hijos",
  ];

  const [checkboxState, setCheckboxState] = React.useState<any>(() => {
    const state: any = {};
    livesWith.forEach((key: string) => {
      state[key] = _.get(values, FinancialFormFieldsEnum.LIVES_WITH).includes(key);
    });

    return state;
  });

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === liveAlone && event.target.checked) {
      setCheckboxState(() => {
        const state: any = {};
        livesWith.forEach((key: string) => {
          state[key] = false;
        });
        state[event.target.name]= event.target.checked;
        return state;
      });
    } else {
      setCheckboxState({
        ...checkboxState,
        [event.target.name]: event.target.checked,
        [liveAlone]: false,
      });
    }
  };

  useEffect(() => {
    const nextState = Object.keys(checkboxState).filter(
      (key) => checkboxState[key] === true
    );
    setFieldValue(FinancialFormFieldsEnum.LIVES_WITH, nextState);
  }, [checkboxState]);

  return (
    <div className="animate__animated animate__fadeIn">
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item sm={6} xs={12}>
          <div style={{ marginBottom: 25 }}>
            <CustomInputTitle
              text="Estado Civil"
              icon={<CoupleLove className="custom-input-color" />}
            />
          </div>
          <FormControl sx={{ marginBottom: 3 }} size="small" fullWidth>
            <OzonSelect
              itemsLabel="Estado Civil"
              fieldName={FinancialFormFieldsEnum.CIVIL_STATUS}
              options={civilStatus}
              handleBlur={handleBlur}
              handleChange={handleChange}
              values={values}
              getFieldMeta={getFieldMeta}
              errors={errors}
            />
          </FormControl>
        </Grid>
        <Grid item sm={12} xs={12}>
          <CustomInputTitle
            text="¿Con quién vives?"
            icon={<Groups className="custom-input-color" />}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <CustomCheckBox
            options={livesWith}
            checkboxState={checkboxState}
            handleCheckboxChange={handleCheckboxChange}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export const StepFourAndHalf: React.FC<StepProps> = () => {
  const {
    values,
    handleChange,
    errors,
    handleBlur,
    getFieldMeta,
    setFieldValue,
  } = useFormikContext<CalculationStepperFormValues>();

  const initialAssetsInfo = [
    { id: 0, text: assetEnum.ninguno, isActive: true, icon: NoneIcon },
    { id: 1, text: assetEnum.casa, isActive: false, icon: HomeIcon },
    { id: 2, text: assetEnum.carro, isActive: false, icon: CarIcon },
    { id: 3, text: assetEnum.moto, isActive: false, icon: MotoIcon },
    { id: 4, text: assetEnum.otro, isActive: false, icon: CubeIcon },
  ];
  const [assetsInfo, setAssetsInfo] = useState(initialAssetsInfo);
  const [showOtherAssetInput, setShowOtherAssetInput] =
    useState<boolean>(false);
  const [isNoneSelected, setIsNoneSelected] = useState(true);
  const getOtherIsActiveValue = assetsInfo.slice(-1)[0].isActive;

  const currentAssets = assetsInfo
    .filter((asset) => asset.isActive)
    .map((item) => item.text);
  const findNoneAssets = currentAssets.find(
    (asset) => asset === assetEnum.ninguno
  );

  useEffect(() => {
    if (currentAssets.length > 1 && isNoneSelected) {
      const currentValue = assetsInfo[0];
      const nextState = { ...currentValue, isActive: false };
      const removeNonOpt = assetsInfo.splice(1);
      const newArray = [nextState, ...removeNonOpt];
      setAssetsInfo(newArray);
    }
    if (currentAssets.length <= 0) {
      setAssetsInfo(initialAssetsInfo);
    }
  }, [currentAssets]);

  useEffect(() => {
    if (findNoneAssets) {
      setIsNoneSelected(true);
      setAssetsInfo(initialAssetsInfo);
    } else {
      setIsNoneSelected(false);
    }
  }, [findNoneAssets]);

  useEffect(() => {
    if (findNoneAssets === assetEnum.ninguno) {
      setAssetsInfo(initialAssetsInfo);
    }
  }, []);

  useEffect(() => {
    if (currentAssets.length > 0) {
      setFieldValue(FinancialFormFieldsEnum.ASSETS, currentAssets);
      setFieldValue(FinancialFormFieldsEnum.OTHER_ASSETS, "");
    }
  }, [assetsInfo]);

  useEffect(() => {
    ReactGA.event("VIEW_Financial_assets", {
      category: "VIEW_Financial_assets",
      label: "page view to the assets information view",
    });
  }, []);

  return (
    <div className="animate__animated animate__fadeIn">
      <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 12, md: 12 }}>
        <Grid item sm={12} xs={12}>
          <CustomInputTitle
            text="¿Con qué activo cuentas?"
            icon={<Building className="custom-input-color" />}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <div className="m_t_md" style={{ display: "flex", flexWrap: "wrap" }}>
            {assetsInfo.map((item, idx) => (
              <AssetCard
                key={idx}
                item={item}
                assetsInfo={assetsInfo}
                setAssetsInfo={setAssetsInfo}
              />
            ))}
            {getOtherIsActiveValue && (
              <Grid item sm={6} xs={6} className="m_t_md">
                <Input
                  type="text"
                  title="¿Con qué OTRO activo cuentas?"
                  name={FinancialFormFieldsEnum.OTHER_ASSETS}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Cuentanos que otro activo posees"
                  icon={<Boy className="primary_300" />}
                  value={_.get(values, FinancialFormFieldsEnum.OTHER_ASSETS)}
                  error={
                    !!_.get(errors, FinancialFormFieldsEnum.OTHER_ASSETS) &&
                    getFieldMeta(FinancialFormFieldsEnum.OTHER_ASSETS).touched
                  }
                  subtitle={
                    getFieldMeta(FinancialFormFieldsEnum.OTHER_ASSETS).touched
                      ? (_.get(
                          errors,
                          FinancialFormFieldsEnum.OTHER_ASSETS
                        ,"") as string)
                      : undefined
                  }
                />
              </Grid>
            )}
          </div>
          <div>
            <Input
              title="¿Eres repartidor en plataformas como Uber, Rappi, Didi u otras?"
              name="workInDigitalPlatforms"
              type="slider"
              inputClassName=""
              icon={<Moto />}
              className="center_x m_t_xl"
              titleClassName=""
              labels
              value={values.workInDigitalPlatforms || false}
              onChange={handleChange}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

// <Input
//   name=FinancialFormFieldsEnum.LIVES_WITH
//   onChange={handleChange}
//   options={[
//     "Solo (a)",
//     "Esposo (a)",
//     "Amigos ",
//     "Padres ",
//     "Pareja estable",
//     "Hermanos ",
//     "Hijos",
//   ]}
//   icon={<Groups />}
//   title="Con quien vives?"
//   type="checkbox"
// />
