/* eslint-disable arrow-body-style */
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Typography, Button, useWindowSize } from "design_system/src";
import { ReactComponent as Motorcycle } from "design_system/src/static/icons/motorcycle.svg";
import { ReactComponent as Right } from "design_system/src/static/icons/chevron-right.svg";

// images
import mainBanner from "static/images/banner/banner_victory.png";
import victory from "static/images/banner/victory.png";
import bannerImg from "static/images/banner/banner_venom.jpg";
import bannerImgDesktop from "static/images/banner/banner_venom_desktop.jpg";
import logoc from "static/logos/logoc.svg";

import "./oneThousand.scss";
import { useDispatch } from "react-redux";
import {
  clearCreditVerificationCreditTimeAction,
  clearCreditVerificationVehicleAction,
} from "store/actions/creditVerification";

export const OneThousandSection = () => {
  const { width: windowWidth } = useWindowSize();

  const [open, setopen] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const showModal = () => {
    dispatch(clearCreditVerificationCreditTimeAction());
    dispatch(clearCreditVerificationVehicleAction());
  };

  return (
    <section className="w_100_per">
      <div className="dso_container p_x_none pos_relative">
        <img
          src={bannerImg}
          alt="victory"
          className="display_none_desktop w_100_per_mobile"
        />
        <img
          src={bannerImgDesktop}
          alt="victory"
          className="display_none_mobile w_100_per_desktop"
        />
        <div className="dso_container p_x_none pos_absolute w_100_per_mobile w_100_per_desktop banner-buttons">
          <div className="display_flex flex_col_mobile h_100_per_mobile h_100_per_desktop">
            <div className="w_45_per_desktop h_50_per_mobile h_100_per_desktop"/>
            <div className="w_55_per_desktop h_50_per_mobile h_100_per_desktop display_flex flex_col flex_align_center flex_justify_end_mobile flex_justify_end_desktop p_b_lg_desktop p_b_xl_mobile">
              <Link to="/financia-tu-moto" onClick={showModal}>
                <Button
                  variant="principal"
                  scale={windowWidth > 800 || windowWidth < 600 ? "large" : "small"}
                >
                  Solicita tu crédito ya
                </Button>
              </Link>
              <Link to="/catalogo">
                <Button
                  variant="ghost"
                  color="white"
                  orientation="right"
                  scale={windowWidth > 800 || windowWidth < 600 ? "large" : "small"}
                  icon={<Right />}
                >
                  Ver catálogo de motos
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
