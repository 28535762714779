import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import { Button, Modal, Typography } from "design_system/src";
import { ReactComponent as Search } from "design_system/src/static/icons/search.svg";
import useVehicles from "hooks/useVehicles";
import InputSearchTextFilter from "./InputSearchTextFilter";
import FilterByHeader from "./FilterByHeader";
import InputFilters from "./InputFilters";
import { TVehicle } from "../../models/vehicle.interface";
import {fetchVehicles, setSelectedFilters} from "../../store/actions/vehicles";

interface Props {
  open?: boolean;
  // eslint-disable-next-line no-unused-vars
  setOpen?: any;
  filterVehicles?: TVehicle[];
  setCurrentPage?:any;
  updateCatalogFilters: any;
}

const FiltersModal: FC<Props> = ({ open = false, setOpen, filterVehicles, setCurrentPage, updateCatalogFilters }) => {
  const dispatch = useDispatch();
  const { selectedFilters } = useVehicles();
  const [selectedTotalFilters, setSelectedTotalFilters] = useState<number>(0);

  useEffect(() => {
    if (selectedFilters) {
      setSelectedTotalFilters(Object.keys(selectedFilters).filter((key) => key !== "limit" && key !== "city" && key !== "page").length);
    }
  }, [selectedFilters]);

  return(
      <Modal setOpen={setOpen} open={open}>
        {selectedTotalFilters > 0 && <div className="display_flex" style={{justifyContent:"flex-end"}}>
          <Button
            onClick={() => {
              dispatch(setSelectedFilters({ city: selectedFilters?.city, limit: selectedFilters?.limit, page: 1 }));
            }}
            variant="principal"
            scale="small"
            className="m_b_xxl"
          >
            Limpiar Filtros
          </Button>
        </div>}
        <InputFilters setCurrentPage={setCurrentPage} updateCatalogFilters={updateCatalogFilters}/>
        <div className=""  style={{paddingBottom:"7.5vh", }}>
          <Button
            className="w_100_per m_t_xl m_b_xxl"
            onClick={() => setOpen(false)}
            icon={<Search />}
          >
              Ver <b>{filterVehicles?.length}</b> moto{filterVehicles?.length !== 1 ? "s" : ""}
          </Button>
        </div>
      </Modal>
  );
};



export default FiltersModal;
