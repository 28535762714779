/* eslint-disable arrow-body-style */
import React from "react";
import logoozon from "static/images/SecctionBoost/logoozon.svg";
import { Typography } from "design_system/src";
import { ReactComponent as Horas } from "design_system/src/static/icons/24h.svg";
import { ReactComponent as ServiceIcon } from "design_system/src/static/icons/customer-service.svg";
import { ReactComponent as Invest } from "design_system/src/static/icons/invest.svg";

import imgPerson from "static/images/banner/mainBanner.png";

import "./boost.scss";

export const SectionBoost = () => {
  return (
    <section
      className="w_100_per bg_neutral_200 p_t_md"
      style={{ borderTop: "1px solid #EAEAEA" }}
    >
      <div className="display_flex dso_container">
        <div className="dso_card bg_neutral_200 w_100_per p_xxl_desktop p_md_mobile display_flex flex_col_mobile">
          <div className="display_flex flex_1_desktop w_50_per_desktop flex_col flex_gap_md">
            <div className="display_flex flex_gap_xl_desktop flex_gap_md_mobile">
              <div className="display_flex">
                <img
                  src={logoozon}
                  alt="ozonlogo"
                  className="dim_30_px_mobile"
                />
              </div>
              <div className="display_flex">
                <Typography
                  weight="600"
                  scale="large"
                  scaleMobile="medium"
                  className="text_neutral_600"
                >
                  ¿Por qué comprar con nosotros?
                </Typography>
              </div>
            </div>
            <div>
              <Typography
                scale="heading3"
                weight="600"
                className="display_none_mobile text_neutral_700"
              >
                Más de{" "}
                <span className="text_primary_300" style={{ fontSize: "3rem" }}>
                  2000
                </span>{" "}
                Motos Entregadas!
              </Typography>
              <div className="display_none_desktop">
                <Typography
                  scale="large"
                  weight="600"
                  className="text_center text_neutral_700"
                >
                  Más de
                </Typography>
                <Typography
                  scale="heading2"
                  weight="600"
                  className="text_center text_primary_300"
                >
                  2000
                </Typography>
                <Typography
                  scale="large"
                  weight="600"
                  className="text_center text_neutral_700"
                >
                  Motos Entregadas!
                </Typography>
              </div>
            </div>
            <div className="m_b_xxl_desktop">
              <Typography
                scale="heading4"
                weight="400"
                scaleMobile="medium"
                className="text_center_mobile text_neutral_700"
              >
                Comprar una moto nunca había sido tan fácil.
              </Typography>
            </div>
            <div className="display_flex display_none_mobile">
              <div className="flex_1 display_flex flex_col flex_align_center">
                <Horas className="text_primary_300 dim_50_per" />
                <Typography scale="medium" weight="600" className="text_center">
                  Fácil y Rápido
                </Typography>
                <Typography scale="medium" weight="400" className="text_center">
                  Te contactamos en menos de 24 horas.
                </Typography>
              </div>
              <div className="flex_1 display_flex flex_col flex_align_center">
                <ServiceIcon className="text_primary_300 dim_50_per" />
                <Typography scale="medium" weight="600" className="text_center">
                  Compra segura
                </Typography>
                <Typography scale="medium" weight="400" className="text_center">
                  No te arriesgues, somos una empresa 100% establecida.
                </Typography>
              </div>
              <div className="flex_1 display_flex flex_col flex_align_center">
                <Invest className="text_primary_300 dim_50_per" />
                <Typography scale="medium" weight="600" className="text_center">
                  Accesible
                </Typography>
                <Typography scale="medium" weight="400" className="text_center">
                  No revisamos buró ni pedimos enganche porque confiamos en ti.
                </Typography>
              </div>
            </div>
          </div>
          <div className="flex_1_desktop w_50_per_desktop display_flex flex_center_desktop flex_col_mobile flex_gap_xl_mobile">
            <img
              src={imgPerson}
              alt="imgperson"
              className="w_80_per_desktop w_100_per_mobile"
            />
            <div className="display_flex display_none_desktop">
              <div className="flex_1 display_flex flex_col flex_align_center">
                <Horas className="text_primary_300 dim_50_per" />
                <Typography scale="medium" weight="600" className="text_center">
                  Fácil y Rápido
                </Typography>
              </div>
              <div className="flex_1 display_flex flex_col flex_align_center">
                <ServiceIcon className="text_primary_300 dim_50_per" />
                <Typography scale="medium" weight="600" className="text_center">
                  Compra segura
                </Typography>
              </div>
              <div className="flex_1 display_flex flex_col flex_align_center">
                <Invest className="text_primary_300 dim_50_per" />
                <Typography scale="medium" weight="600" className="text_center">
                  Accesible
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
