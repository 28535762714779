import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { indexOf } from "lodash";
import moment from "moment";

import { Button, Typography } from "design_system/src";

import { store } from "store";
import { useFinancialForm } from "hooks/useGetFinancialForm";
import { FinancialFormStatus } from "models/financialForm.interface";
import { StatusHistoric } from "enums/statusHistoric.enum";
import { FinancialFormDocs } from "enums/financialFormDocs.enum";

import { StepperItemProps, StepperItem, descriptionItemProps } from "../components/stepperItem/StepperItem";
import { CreditValidationStep, DocumentsStep, MotorcycleStep, ReservationStep, SchedulingStep } from "./steps";
import { WhatsappButton } from "../components/whatsappButton/WhatsappButton";

// eslint-disable-next-line no-shadow,no-unused-vars
enum ApplicationStatus {
    DOCUMENTS = "documents",
    VALIDATION = "validation",
    MOTORCYCLE= "motorcycle_selection",
    RESERVATION= "reservation_receipt",
    SCHEDULE= "delivery_scheduling",
}
type StepType = ApplicationStatus;

const stepSchema: StepperItemProps[] = [
    {
        step: ApplicationStatus.DOCUMENTS,
        title: "Registro de documentos",
        completed: false,
        active: false,
        enabled: false,
        component: <DocumentsStep/>
    },
    {
        step: ApplicationStatus.VALIDATION,
        title: "Validación de crédito",
        completed: false,
        active: false,
        enabled: false,
        component: <CreditValidationStep/>
    },
    {
        step: ApplicationStatus.MOTORCYCLE,
        title: "Selección de moto",
        completed: false,
        active: false,
        enabled: false,
        component: <MotorcycleStep/>
    },
    {
        step: ApplicationStatus.RESERVATION,
        title: "Comprobante de apartado",
        completed: false,
        active: false,
        enabled: false,
        component: <ReservationStep/>
    },
    {
        step: ApplicationStatus.SCHEDULE,
        title: "Agendar entrega",
        completed: false,
        active: false,
        enabled: false,
        component: <SchedulingStep/>
    },
];

export const ApplicationScreen = () => {
    const matchesMD = useMediaQuery("(min-width:924px)");
    const matchesXS = useMediaQuery("(min-width:600px)");
    const { user } = store.getState().userReducer;
    const navigate = useNavigate();
    const [openDetail, setOpenDetail] = useState<boolean>(matchesMD);
    const [activeSection, setActiveSection] = useState<StepType | null>(null);
    const [documentSection, setDocumentSection] = useState<StepperItemProps[]>(stepSchema);
    const [stepBody, setStepBody] = useState<any>();
    const [financialForm, setFinancialForm] = useFinancialForm();
    const [showContinueBtn, setShowContinueBtn] = useState<boolean>(true);

    const getEnabledStatus = (value: StepType) => {
        const enabledStatus: any[] = [];
        let shouldSkip = false;
        stepSchema.map((e) => e.step).forEach((step) => {
            if (step === value) {
                shouldSkip = true;
            }
            if (!shouldSkip) {
                enabledStatus.push(step);
            }
        });
        return enabledStatus;
    };

    const getDescription = (step: StepType): descriptionItemProps | undefined => {
        if (step === ApplicationStatus.DOCUMENTS) {
            if (financialForm?.status === FinancialFormStatus.PENDING) {
                return { text: "En curso" };
            }
            return undefined;
            // eslint-disable-next-line no-else-return
        } else if (step === ApplicationStatus.VALIDATION) {
            const historics = financialForm?.statusHistoric?.filter(({status}) => status === StatusHistoric.APPROVED);
            if (historics && historics.length > 0 && financialForm?.status !== FinancialFormStatus.PENDING && financialForm?.status !== FinancialFormStatus.IN_VALIDATION) {
                return {
                    text: `Aprobado ${moment(historics[historics.length - 1].updatedAt).utcOffset(-360).format("YYYY-MM-DD hh:mm A")}`,
                    color: "green_300"
                };
            }
            if (financialForm?.status === FinancialFormStatus.REJECTED) {
                return {
                    text: "Rechazado",
                    color: "red_300"
                };
            }
        } else if (step === ApplicationStatus.MOTORCYCLE) {
            return undefined;
        } else if (step === ApplicationStatus.RESERVATION) {
            const historics = financialForm?.statusHistoric?.filter(({status}) => status === StatusHistoric.UPLOAD_RECEIPT);
            const layawayReceipt = financialForm!.documents.filter((e) => e.typeEvidence === FinancialFormDocs.RESERVATION_RECEIPT).length > 0;
            if (historics && historics.length > 0 && layawayReceipt) {
                return {
                    text: `${moment(historics[historics.length - 1].updatedAt).utcOffset(-360).format("YYYY-MM-DD hh:mm A")}`,
                    color: "green_300"
                };
            }
        } else if (step === ApplicationStatus.SCHEDULE) {
            const historics = financialForm?.statusHistoric?.filter(({status}) => status === StatusHistoric.SCHEDULED);
            if (historics && historics.length > 0) {
                return {
                    text: `${moment(historics[historics.length - 1].updatedAt).utcOffset(-360).format("YYYY-MM-DD hh:mm A")}`,
                    color: "green_300"
                };
            }
        }
        return { text: "Pendiente" };
    };

    useEffect(() => {
        setOpenDetail(matchesMD);
        if (financialForm) {
            const pendingStatus = financialForm.status === FinancialFormStatus.PENDING;
            const approvedStatus = financialForm.status === FinancialFormStatus.APPROVED;
            const inValidationStatus = financialForm.status === FinancialFormStatus.IN_VALIDATION || financialForm.status === FinancialFormStatus.REJECTED;
            const completedStatus = financialForm.status === FinancialFormStatus.SCHEDULED || financialForm.status === FinancialFormStatus.COMPLETED;

            const vehicleSelected = financialForm.vehicle !== null && financialForm.vehicleAvailable === true;
            const layawayReceipt = financialForm.documents.filter((e) => e.typeEvidence === FinancialFormDocs.RESERVATION_RECEIPT).length > 0;
            const appointmentCompleted = financialForm.appointment?.scheduledAt !== null;
            let step: StepType | null = null;

            if (pendingStatus) {
                step = ApplicationStatus.DOCUMENTS;
            } else if (inValidationStatus) {
                step = ApplicationStatus.VALIDATION;
            } else if (approvedStatus && !vehicleSelected) {
                step = ApplicationStatus.MOTORCYCLE;
            } else if (approvedStatus && vehicleSelected && !layawayReceipt) {
                step = ApplicationStatus.RESERVATION;
            } else if ((approvedStatus && layawayReceipt) || completedStatus) {
                step = ApplicationStatus.SCHEDULE;
            } else {
                navigate("/mi-cuenta");
            }

            if (step !== null) {
                setDocumentSection(documentSection.map((e) => {
                    e.enabled = getEnabledStatus(step!).includes(e.step) || e.step === step;
                    e.completed = getEnabledStatus(step!).includes(e.step) || (appointmentCompleted && step === ApplicationStatus.SCHEDULE);
                    e.description = getDescription(e.step as StepType);
                    return e;
                }));
                setActiveSection(step);
            }
        }
    }, [matchesMD]);

    useEffect(() => {
        if (financialForm?.status === FinancialFormStatus.COMPLETED) {
            navigate("/mi-cuenta");
            return;
        }
        setDocumentSection(documentSection.map((e) => {
            e.active = e.step === activeSection;
            e.description = getDescription(e.step as StepType);
            return e;
        }));
        setStepBody(documentSection
            .filter(({step}) => step === activeSection)
            .map(({component}) => component)[0]
        );
        if (!matchesMD) {
            setOpenDetail(activeSection !== null);
        }
    }, [activeSection, financialForm]);

    useEffect(() => {
        const index = indexOf(documentSection.map((e) => e.step), activeSection);
        if (documentSection[index + 1]) {
            setShowContinueBtn(documentSection[index + 1].enabled);
        } else {
            setShowContinueBtn(false);
        }
    }, [activeSection]);

    const handleContinueButton = () => {
        const index = indexOf(documentSection.map((e) => e.step), activeSection);
        if (documentSection[index + 1]) {
            if (documentSection[index + 1].enabled) {
                window.scrollTo(0, 0);
                setActiveSection(documentSection[index + 1].step as StepType);
            }
        }
    };

    const handleBackButton = () => {
        window.scrollTo(0, 0);
        setActiveSection(null);
    };

    return <div className="dso_container" style={{ minHeight: "75vh" }}>
        <div className="m_y_lg_mobile m_y_xxxl p_y_xxl_mobile display_flex_desktop">
            {(!openDetail || matchesMD) && <div className={`w_100_per_mobile ${matchesXS && !matchesMD ? " w_300_px m_x_auto" : ""}${matchesMD ? " w_400_px" : ""}`}>
                <div className="p_t_xxxl_desktop">
                    <Typography className="m_y_none m_b_md text_center_mobile" weight="400" scale="heading4">
                        Bienvenid@ <strong>{user.name}</strong>
                    </Typography>
                    <Typography className="m_y_none text_center_mobile" weight="400" scale="medium">
                        Aquí puedes hacer seguimiento al estado de tu financiamiento
                    </Typography>
                </div>
                <div className="m_y_lg">
                    {documentSection.map((item, index) => {
                        return <StepperItem
                            key={index}
                            props={item}
                            position={{
                                index,
                                lastPosition: index === documentSection.length - 1,
                                action: (value: StepType) => setActiveSection(value)
                            }}
                        />;
                    })}
                    <div className="m_y_xl">
                        <WhatsappButton/>
                    </div>
                </div>
            </div>}
            {openDetail && <div className="w_100_per_mobile flex_grow_1_desktop ">
                <div className="shadow_hard_desktop br_xs_desktop m_l_sm_desktop p_x_xl_desktop p_y_xxxl_desktop">
                    {!matchesMD && <div>
                        <Typography className="m_y_none m_b_md text_center" weight="400" scale="heading4">
                            Bienvenid@ <strong>{user.name}</strong>
                        </Typography>
                    </div>}
                    {stepBody}
                    <div className="w_100_per_mobile w_300_px_desktop m_b_md m_x_auto">
                        {showContinueBtn && <Button className="w_100_per" scale="small" onClick={handleContinueButton}>
                            <Typography weight="600" scale="medium" scaleMobile="medium">
                                Continuar
                            </Typography>
                        </Button>}
                    </div>
                    {!matchesMD && <Button className="w_100_per p_y_none" scale="small" variant="ghost" onClick={handleBackButton}>
                        <Typography weight="600" scale="medium" scaleMobile="medium" textColor="neutral_700">
                            Volver
                        </Typography>
                    </Button>}
                </div>
            </div>}
        </div>
    </div>;
};
