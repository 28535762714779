/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export enum FinancialFormFieldsEnum {
  EMAIL="checkInStep.email",
  AUTH_DATA_TREATMENT="checkInStep.authDataTreatment",
  // userInfoStep
  NAME = "userInfoStep.name",
  FIRST_LAST_NAME = "userInfoStep.firstLastName",
  SECOND_LAST_NAME = "userInfoStep.secondLastName",
  PHONE = "userInfoStep.phone",
  ADDRESS = "userInfoStep.address",
  AGE = "userInfoStep.age",
  CURP = "userInfoStep.curp",
  GENDER = "userAddressStep.gender",
  DAY = "userAddressStep.day",
  MONTH = "userAddressStep.month",
  YEAR = "userAddressStep.year",

  CP = "userAddressStep.fullAddress.cp",
  STATE = "userAddressStep.fullAddress.state",
  DELEGATION = "userAddressStep.fullAddress.delegation",
  NEIGHBORHOOD = "userAddressStep.fullAddress.neighborhood",
  STREET = "userAddressStep.fullAddress.street",
  EXT_NUMBER = "userAddressStep.fullAddress.extNumber",
  INT_NUMBER = "userAddressStep.fullAddress.intNumber",
  LAT = "userAddressStep.fullAddress.lat",
  LONG = "userAddressStep.fullAddress.long",

  // incomeStep
  MONTHLY_INCOME = "incomeStep.monthlyIncome",
  MONTHLY_OUTCOME = "incomeStep.monthlySpending",
  // dependantsStep
  CHILDREN_COUNT = "dependantsStep.childrenCount",
  DEPENDANTS_COUNT = "dependantsStep.dependantsCount",
  ECONOMIC_ACTIVITY_VALUE = "dependantsStep1.economicActivity.value",
  ECONOMIC_ACTIVITY_DESCRIPTION = "dependantsStep1.economicActivity.description",
  COMPANY_NAME = "dependantsStep1.companyName",
  EDUCATIONAL_LEVEL = "dependantsStep1.educationalLevel",
  // userStatusStep
  CIVIL_STATUS = "userStatusStep.civilStatus",
  LIVES_WITH = "userStatusStep.livesWith",
  ASSETS = "userStatusStep1.assets",
  OTHER_ASSETS = "userStatusStep1.otherAsset",
}
